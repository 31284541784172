import React, { useState } from "react";
import moment from "moment";
import Calendar from "../Calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

const DatePicker = ({
  open,
  setDate,
  startDate,
  requestSelectDate,
  width,
  disabledDates,
}) => {
  const [showCalendar, setShowCalendar] = useState(open);
  return (
    <>
      {showCalendar ? (
        <Calendar
          startDate={startDate}
          handleSelectDate={date => {
            setDate(date);
            setShowCalendar(false);
          }}
          handleClose={() => setShowCalendar(false)}
          width={width}
          disabledDates={disabledDates}
        />
      ) : (
        <button className="btn btn-link" onClick={() => setShowCalendar(true)}>
          <FontAwesomeIcon icon={faCalendar} />
          <span className="ml-3">
            {requestSelectDate
              ? "Select Date"
              : startDate
              ? moment(startDate).format("MM-DD-YYYY")
              : "Set Date"}
          </span>
        </button>
      )}
    </>
  );
};

export default DatePicker;
