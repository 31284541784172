import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const ExpandBtn = styled.div`
  background-color: #e4e7ef;
  color: #8f95a7;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  position: relative;
  top: 6.6rem;
  left: -4rem;
  width: 3.5rem;
  justify-content: center;
  align-items: center;
`;

const RowExpand = ({ dataRow, expandedRow, setExpandedRow, rowExpand }) => {
  return (
    <ExpandBtn
      onClick={() => {
        expandedRow.rowId === dataRow.id
          ? setExpandedRow({ rowId: null, expandedContent: null })
          : setExpandedRow({ rowId: dataRow.id, expandedContent: rowExpand(dataRow) });
      }}
    >
      <FontAwesomeIcon icon={expandedRow.rowId === dataRow.id ? faChevronDown : faChevronRight} />
    </ExpandBtn>
  );
};

export default RowExpand;
