import React from 'react';

import { HeaderCellContainer } from './styled';

const HeaderCell = (props) => {
  const { align, text, width } = props;
  return (
    <HeaderCellContainer id={props.columnName} width={width} align={align}>
      {text}
    </HeaderCellContainer>
  );
};

export default HeaderCell;
