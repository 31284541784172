import React from "react";
import styled from "@emotion/styled";

const FooterWrapper = styled.footer`
  height: ${props => props.theme.headerHeight || "7.9rem"};
  background-color: ${props => props.theme.colors.fg || "#FCFCFD"};
  box-shadow: 0 19px 58px -20px rgba(34, 46, 78, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding: 0 2rem;
`;

const Footer = ({ left, right }) => {
  return (
    <FooterWrapper>
      <div>{left}</div>
      <div>{right}</div>
    </FooterWrapper>
  );
};

export default Footer;
