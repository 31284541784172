import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useGlobalState } from "store";
import { publicRoutes, privateRoutes } from "routes";

import APIService from "services/backstrap/apiService";
import LoadingScreen from "components/UI/Progress/LoadingScreen";
import Toast from "components/UI/Toast/Toast";
import SignIn from "screens/SignIn";

const App = () => {
  const [appState, appDispatch] = useGlobalState();

  //Auth & Protected Routes
  const PrivateRoute = ({ component, ...options }) => {
    const finalComponent = appState.isAuthenticated ? component : SignIn;
    return <Route {...options} component={finalComponent} />;
  };

  function authCheck() {
    //check if token is valid
    const savedToken = localStorage.getItem("jwt");
    if (savedToken) {
      APIService.checkToken()
        .then(res => {
          appDispatch({ type: "LOADING_DISABLED" });
          appDispatch({ type: "USER_IS_AUTHENTICATED" });
        })
        .catch(err => {
          console.log("jwt error:", err);
          if (err.status === 401) {
            localStorage.removeItem("jwt");
            appDispatch({ type: "LOADING_DISABLED" });
          }
        });
    }
  }

  React.useEffect(() => authCheck(), [appState.bs_user.cs_token]);

  //404 Component
  const NotFound = () => (
    <div>
      <h3>404 - Not Found</h3>
    </div>
  );

  //Toast Notifications
  function clearNotification(idx) {
    appDispatch({ type: "CLEAR_NOTIFICATION", payload: idx });
  }

  return (
    <Router>
      <div className="app-container">
        {appState.appLoading === true && (
          <LoadingScreen
            message={appState.appLoadingMessage && appState.appLoadingMessage}
          />
        )}
        <Switch>
          <Suspense fallback={LoadingScreen}>
            {publicRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))}
            {privateRoutes.map((route, idx) => (
              <PrivateRoute
                key={idx}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))}
          </Suspense>
          <Route component={NotFound} />
        </Switch>
      </div>

      {appState.notifications.length > 0 &&
        appState.notifications.map((toast, idx) => (
          <Toast
            type={toast.type}
            active={toast.active}
            close={() => clearNotification(idx)}
            message={toast.message}
            errors={toast.errors}
          />
        ))}
    </Router>
  );
};

export default App;
