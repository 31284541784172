import React, { useState, useEffect } from "react";
import moment from "moment";
import Table from "components/UI/Table/index";
import useTableData from "components/UI/Table/useTableData";
import ExpandedJobsComponent from "./ExpandedJobsComponent";
import APIService from "services/backstrap/apiService";

const JobList = props => {
  const [state, setState] = useState({
    isCardView: false,
    data: [],
  });
  const columns = [
    {
      name: "class",
      dataName: "mail_type",
      text: "CLASS",
      width: "10%",
    },
    {
      name: "job_id",
      dataName: "id",
      text: "JOB ID",
      expanded: val => <ExpandedJobsComponent details={val} />,
      width: "10%",
    },
    {
      name: "current-stage",
      dataName: "mail_status",
      text: "CURRENT STAGE",
      width: "30%",
    },
    {
      name: "letters",
      dataName: "letter_count",
      text: "LETTERS",
      width: "20%",
    },
    {
      name: "mail-by",
      dataName: "mail_date",
      dataFormat: val => moment(val).format("MM-DD-YYYY"),
      text: "MAIL BY DATE",
      width: "15%",
    },
    {
      name: "details",
      dataName: "detail_link",
      text: "Details",
      width: "15%",
      align: "center",
      link: row => {
        return {
          text: `/job-details/:jobId`,
          params: {
            ":jobId": row.id,
          },
          buttonText: `View Job`,
        };
      },
    },
  ];

  const rowOptions = [
    {
      label: "Expand",
      name: "expand",
      expanded: row => <ExpandedJobsComponent data={row} />,
    },
  ];

  const { tableData } = useTableData(columns, state.data);

  useEffect(() => {
    APIService.searchPrintJobs()
      .then(response => {
        console.log(response, "searchPrintJobs");
        setState({ data: response.results }, () => {});
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return state.data && state.data.length ? (
    <Table
      name="jobs"
      headerColumns={columns}
      data={tableData}
      rowOptions={rowOptions}></Table>
  ) : null;
};

export default JobList;
