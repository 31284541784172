import React, { useEffect, useState, useRef } from "react";
import { Spring, animated } from "react-spring/renderprops";
import { RadialGradient } from "@vx/gradient";

import Modal from "../Layout/Modal/ModalContainer";
import { Flex } from "../Layout";

const LoadingScreen = ({ fill = "rgba(0,0,0,0.1)", message }) => {
  const [offset, setOffset] = useState(0);
  const path = useRef();

  useEffect(() => setOffset(path.current.getTotalLength()), []);
  return (
    <Modal>
      <Flex justify="center" align="center">
        <svg width="151" height="141" viewBox="0 0 151 141">
          <RadialGradient id="orange" from={fill} to="#FF6700" />
          <g fill={fill} stroke="url(#orange)" strokeWidth="2">
            <Spring
              native
              reset
              from={{ dash: offset }}
              to={{ dash: 0 }}
              config={{ delay: 300, duration: 666 }}>
              {props => (
                <animated.path
                  id="a"
                  ref={path}
                  strokeDasharray={offset}
                  strokeDashoffset={props.dash}
                  d="M103.9 5.605L75.272 19.838c16.584 9.41 24.332
                17.35 39.14
                13.775h.002l26.858-15.485c-2.933-6.31-6.918-11.201-12.17-14.181-
                12.612-7.159-18.826-1.485-25.202 1.658"
                />
              )}
            </Spring>
          </g>
          <RadialGradient id="red" from={fill} to="#E91C1C" />
          <g fill={fill} stroke="url(#red)" strokeWidth="2">
            <Spring
              native
              reset
              from={{ dash: offset }}
              to={{ dash: 0 }}
              config={{ delay: 500, duration: 666 }}>
              {props => (
                <animated.path
                  id="b"
                  ref={path}
                  strokeDasharray={offset}
                  strokeDashoffset={props.dash}
                  d="M75.869
                19.724l-12.672-7.09C40.947.116 38.713-.493
                32.582 1.144c-8.233 2.197-18.173 8.402-22.31
                17.864l53.496 30.4c8.415 4.191 16.107 4.087
                22.564-.209l27.94-16.05c-14.254
                4.03-22.074-4.122-38.403-13.425"
                />
              )}
            </Spring>
          </g>
          <RadialGradient id="purple" from={fill} to="#A450FF" />
          <g fill={fill} stroke="url(#purple)" strokeWidth="2">
            <Spring
              native
              reset
              from={{ dash: offset }}
              to={{ dash: 0 }}
              config={{ delay: 800, duration: 666 }}>
              {props => (
                <animated.path
                  id="c"
                  ref={path}
                  strokeDasharray={offset}
                  strokeDashoffset={props.dash}
                  d="M13.837 64.993l25.987 17.494c.02-19.004
                3.09-29.775-7.118-40.656L6.504 26.487C2.54
                32.178.28 38.076.272 44.093c-.015 14.454 7.807 16.98 13.565 20.9"
                />
              )}
            </Spring>
          </g>
          <RadialGradient id="yellow" from={fill} to="#F3C343" />
          <g fill={fill} stroke="url(#yellow)" strokeWidth="2">
            <Spring
              native
              reset
              from={{ dash: offset }}
              to={{ dash: 0 }}
              config={{ delay: 800, duration: 666 }}>
              {props => (
                <animated.path
                  id="d"
                  ref={path}
                  strokeDasharray={offset}
                  strokeDashoffset={props.dash}
                  d="M111.223 83.424l12.514-7.267c22.033-12.724
                23.645-14.397 25.304-20.472 2.228-8.161
                1.796-19.873-4.338-28.198L91.469 57.93c-7.862
                5.087-11.597 11.793-11.111 19.492l-.086
                32.065c3.662-14.242 14.68-16.782 30.95-26.063"
                />
              )}
            </Spring>
          </g>
          <RadialGradient id="green" from={fill} to="#3FD674" />
          <g fill={fill} stroke="url(#green)" strokeWidth="2">
            <Spring
              native
              reset
              from={{ dash: offset }}
              to={{ dash: 0 }}
              config={{ delay: 800, duration: 666 }}>
              {props => (
                <animated.path
                  id="e"
                  ref={path}
                  strokeDasharray={offset}
                  strokeDashoffset={props.dash}
                  d="M110.22 114.694l2.052-31.207c-16.705 9.161-27.658
                11.945-31.95 25.415l-.05 30.354c7.048.67 13.35-.053 18.637-2.953
                12.708-6.967 10.842-14.752 11.311-21.609"
                />
              )}
            </Spring>
          </g>
          <RadialGradient id="blue" from={fill} to="#06A3FF" />
          <g fill={fill} stroke="url(#blue)" strokeWidth="2">
            <Spring
              native
              reset
              from={{ dash: offset }}
              to={{ dash: 0 }}
              config={{ delay: 800, duration: 666 }}>
              {props => (
                <animated.path
                  id="f"
                  ref={path}
                  strokeDasharray={offset}
                  strokeDashoffset={props.dash}
                  d="M39.72 97.401c.11
                25.724.846 27.983 5.376 32.5 6.084 6.07
                16.88 11.601 27.176
                10.428V78.315c-1.03-9.456-4.628-16.117-11.675-
                19.578l-28.325-16.25C42.67 52.961
                39.74 63.826 39.72 82.763V97.4z"
                />
              )}
            </Spring>
          </g>
        </svg>
        {message && <p>{message}</p>}
      </Flex>
    </Modal>
  );
};

export default LoadingScreen;
