import React from 'react';

import { ConfirmationRowContainer } from './styled';

const ConfirmationRow = ({ active, children, cancel, confirmAction }) => {
  return (
    <ConfirmationRowContainer className={active ? 'active' : ''}>
      <div>{children}</div>
      <div>
        <button className="yes" onClick={confirmAction}>
          <i className="fa fa-check"></i>
        </button>
        <button className="no" onClick={cancel}>
          <i className="fa fa-times"></i>
        </button>
      </div>
    </ConfirmationRowContainer>
  );
};

export default ConfirmationRow;
