import tokenReplace from "services/tokenReplace";
const useTableData = (headers, data) => {
  let tableData = [];
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  for (let rowN = 0; rowN < data.length; rowN++) {
    let dataRow = [];
    let links = [];
    let rowLink = null;

    for (let colN = 0; colN < headers.length; colN++) {
      const dataName = headers[colN].dataName;
      let dataValue = data[rowN][dataName] == null ? "-" : data[rowN][dataName];

      if (headers[colN].dataFormat) {
        dataValue = headers[colN].dataFormat(dataValue);
      }

      if (headers[colN].isCurrency && dataValue !== null) {
        dataValue = formatter.format(dataValue);
      }

      dataRow.push(dataValue);

      if (headers[colN].link) {
        let dataLink = headers[colN].link(data[rowN]);
        let linkText = dataLink.text;
        if (dataLink.params) {
          linkText = tokenReplace(linkText, dataLink.params);
        }

        let link = {};
        if (dataLink.rowLink) {
          rowLink = {
            text: linkText,
          };
        } else {
          link = {
            text: linkText,
            cell: dataName,
            buttonText: dataLink.buttonText ? dataLink.buttonText : null,
          };
          links.push(link);
        }
      }
    }

    tableData.push({
      id: data[rowN].id,
      data: dataRow,
      links,
      rowLink,
      original: data[rowN],
    });
  }
  return {
    tableData,
  };
};

export default useTableData;
