import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${props => props.p || 0};
  margin: ${props => props.m || 0};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  flex-direction: ${props => props.direction || "row"};
`;

export const Page = styled.main`
  padding: 3.8rem 15px 1.5rem;
`;
export const App = styled.div``;
