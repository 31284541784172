export default {
  colors: {
    bg: '#f6f6fb',
    fg: '#FCFCFD',
    text: '#222e4e',
    lightBgText: '#9096a8',
    lightFgText: '#b6bac5',
    progressBg: '#e4e7ef',
    grey: '#8F95A7',
    lightGrey: '#E4E7EF',
    blue: '#5282f6',
    lightBlue: '#e3ebff',
    red: '#fc5e87',
    lightRed: '#fff4f7',
    yellow: '#ffc000',
    lightYellow: '#fffbef',
    green: '#00d98b',
    lightGreen: '#dcfff3',
  },
  btn: {
    border: '1px solid #E4E7EF',
  },
  baseFontSize: '62.5%',
  font: `'Mulish', sans-serif`,
  borderRadius: '4px',
  boxShadow: `0px 3px 10px rgba(167,167,167,.29)`,
  headerHeight: `8rem`,
};
