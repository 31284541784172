import styled from "@emotion/styled";

export const Button = styled.button`
  background-color: ${props =>
    props.btnLink && !props.active
      ? "transparent"
      : props.bg || props.theme.colors.fg || "#FCFCFD"};
  border: ${props =>
    props.btnLink
      ? "1px solid transparent"
      : props.border || props.theme.btn.border || "1px solid #E4E7EF"};
  box-shadow: ${props =>
    props.btnLink && !props.active
      ? "none"
      : props.boxShadow ||
        props.theme.boxShadow ||
        "0px 3px 10px rgba(167,167,167,.29)"};
  color: ${props =>
    props.active
      ? "#5282f6"
      : props.color || props.theme.colors.text || "#222e4e"};
  padding: ${props => (props.p ? props.p : "1.2rem 2.5rem")};
  margin: ${props => (props.m ? props.m : "1rem")};
  border-radius: 0.5rem;
  font-weight: 700;
  transition: background-color 500ms, border 500ms, box-shadow 500ms,
    color 300ms;
  &:hover,
  &:active {
    background-color: ${props =>
      props.bg || props.theme.colors.fg || "#FCFCFD"};
    color: ${props => props.theme.colors.blue || "#5282f6"};
    border: ${props =>
      props.border || props.theme.btn.border || "1px solid #E4E7EF"};
    box-shadow: ${props =>
      props.boxShadow ||
      props.theme.boxShadow ||
      "0px 3px 10px rgba(167,167,167,.29)"};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.lightGrey || "#E4E7EF"};
    box-shadow: none;
    color: ${props => props.theme.colors.grey || "#8F95A7"};
    svg path {
      fill: ${props => props.theme.colors.grey || "#8F95A7"};
    }
  }
`;
