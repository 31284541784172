import React from 'react';
import styled from '@emotion/styled';
import { Button } from '../Button';

export const TabButtonStyled = styled(Button)`
  &:first-of-type {
    margin-left: 0;
  }
`;
export const TabButtons = styled.div``;
export const TabButton = (props) => <TabButtonStyled btnLink {...props} />;
