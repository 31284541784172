import React, { useEffect } from "react";
import { useTheme } from "emotion-theming";
import {
  faCircle,
  faCircleNotch,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "components/UI/Progress/ProgressBar";
import IconText from "components/UI/IconText";
import { Flex } from "components/UI/Layout";

const ProgressBarWrap = props => {
  const theme = useTheme();

  return (
    <div className="progress-bar-wrap">
      <Flex justify="space-between" p="1rem 0">
        <IconText
          icon={faDotCircle}
          text={"Accepted"}
          color={
            props.status === "ACCEPTED" ? theme.colors.green : theme.colors.grey
          }
        />
        <IconText
          icon={faDotCircle}
          text={"Presort"}
          color={
            props.status === "PRESORT" ? theme.colors.green : theme.colors.grey
          }
        />
        <IconText
          icon={faDotCircle}
          text={"Prepress"}
          color={
            props.status === "PREPRESS" ? theme.colors.green : theme.colors.grey
          }
        />
        <IconText
          icon={faDotCircle}
          text={"Printing"}
          color={
            props.status === "PRINTING" ? theme.colors.green : theme.colors.grey
          }
        />
        <IconText
          icon={faDotCircle}
          text={"Finishing"}
          color={
            props.status === "FINISHING"
              ? theme.colors.green
              : theme.colors.grey
          }
        />
        <IconText
          icon={faDotCircle}
          text={"Loading"}
          color={
            props.status === "LOADING" ? theme.colors.green : theme.colors.grey
          }
        />
        <IconText
          icon={faDotCircle}
          text={"In Transit"}
          color={
            props.status === "IN-TRANSIT"
              ? theme.colors.green
              : theme.colors.grey
          }
        />
        <IconText
          icon={faDotCircle}
          text={"Mailed"}
          color={
            props.status === "MAILED" ? theme.colors.green : theme.colors.grey
          }
        />
      </Flex>
      <ProgressBar progress={`${props.progress}%`} bg={theme.colors.green} />
    </div>
  );
};

export default ProgressBarWrap;
