import React from 'react';
import {Link} from 'react-router-dom';
import {CellContainer, InlineLinks} from './styled';

const Cell = props => {
  const {align, data, link, width, buttonText} = props;

  if (link && link.includes('job-details')) {
    const ids = link.match(/\d+/g);

    if (ids) {
      return (
        <CellContainer width={width} align={align} textWrap>
          <InlineLinks>
            <p>
              {ids.map((id, index) => (
                <>
                  <Link to={`/job-details/${id}`}>{id}</Link>
                  {ids.length - 1 !== index && ', '}
                </>
              ))}
            </p>
          </InlineLinks>
        </CellContainer>
      );
    }

    return (
      <CellContainer width={width} align={align} textWrap>
        -
      </CellContainer>
    );
  }

  return (
    <CellContainer width={width} align={align}>
      {link ? (
        link.slice(0, 4) === 'http' ? (
          <a href={link} target="_blank" noref>
            {buttonText ? buttonText : data}
          </a>
        ) : (
          <Link to={link}>{buttonText ? buttonText : data}</Link>
        )
      ) : (
        data
      )}
    </CellContainer>
  );
};

export default Cell;
