import styled from "@emotion/styled";

export const SiteTitle = styled.h1`
  font-size: 2.1rem;
  font-weight: 900;
  display: inline-block;
  padding: ${props => props.p};
  margin: ${props => props.m};
`;

export const Text = styled.span`
  font-weight: ${props => props.weight};
  color: ${props => props.color};
  font-size: ${props => props.size || "inherit"};
  margin: ${props => props.m};
`;

export const PageTitle = styled.h2`
  color: ${props => props.theme.colors.text};
  font-weight: 900;
  padding: ${props => props.p};
  margin: ${props => props.m};
`;
