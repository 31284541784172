import React from "react";
import styled from "@emotion/styled";
import TextInput from "./TextInput";
import { Button } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const SaveInputWrapper = styled.div`
  &&& {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
    background: ${props => (props.bg ? props.bg : "#e3ebff")};
    padding: 0.5rem 2rem;
    .search-text-btn-wrap {
      display: flex;
      align-items: center;
    }
    .search-text-input {
      margin: 0 !important;
      input {
        background: white !important;
        font-size: 1em;
        ::placeholder,
        ::-ms-input-placeholder {
          color: #8f95a7 !important;
        }
        :-ms-input-placeholder {
          color: #8f95a7 !important;
        }
      }
    }
  }
`;

const SaveInput = props => (
  <>
    <SaveInputWrapper bg={props.bg}>
      {props.text && <strong>{props.text}</strong>}
      <div className="search-text-btn-wrap">
        <TextInput className="search-text-input" {...props} />
        <Button onClick={props.onSubmit} p="0.5rem 1rem">
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>
    </SaveInputWrapper>
  </>
);

export default SaveInput;
