import React from "react";
import styled from "@emotion/styled";
import TextInputOG from "../TextInput/TextInput";

const TextInputWrapper = styled.div`
  &&& {
    input {
      font-size: 2rem;
    }
  }
`;

const TextInput = props => (
  <TextInputWrapper>
    <TextInputOG {...props} />
  </TextInputWrapper>
);

export default TextInput;
