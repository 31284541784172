import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";

export const CardWrapper = styled.div`
  &&& {
    flex: 0 0 22.5%;
    margin: 1.5rem 0;
    min-width: ${props => props.width || "270px"};
    border-radius: 0.5rem;
    box-shadow: ${props => props.theme.boxShadow};
    padding: 1.8rem 0;
  }
`;
export const CardDeckWrapper = styled.div``;

export const Card = props => (
  <CardWrapper
    width={props.width}
    className={props.wrapClass ? `card ${props.wrapClass}` : `card`}>
    {props.children}
  </CardWrapper>
);

export const CardDeck = ({ children }) => {
  const theme = useTheme();
  return (
    <CardDeckWrapper theme={theme} className="card-deck">
      {children}
    </CardDeckWrapper>
  );
};
