import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {TableContainer, TableBody, HeaderRow} from './styled';
import HeaderCell from './HeaderCell';
import Row from './Row';
import Cell from './Cell';
import ConfirmationRow from './ConfirmationRow';
import TableModal from './TableModal';
import RowDropdown from './RowDropdown';
import RowExpand from './RowExpand';

const hasDataRows = data => data && data.length > 0;
const hasDataValues = dataRow => dataRow && dataRow.data.length > 0;

const Table = ({
  data,
  headerColumns,
  rowOptions,
  rowExpand,
  name,
  selectOptions,
}) => {
  const [confirmRow, setConfirmRow] = useState({
    rowId: null,
    action: null,
    message: '',
  });
  const [modalRow, setModalRow] = useState({
    rowId: null,
    modal: null,
  });
  const [expandedRow, setExpandedRow] = useState({
    rowId: null,
    expandedContent: null,
  });
  const [selectedRow, setSelectedRow] = useState({
    rowIds: selectOptions ? selectOptions[0].items : [],
    action: null,
  });

  const cancelConfirm = () =>
    setConfirmRow({rowId: null, action: null, message: ''});
  const displayConfirmationRow = id => confirmRow && confirmRow.rowId === id;
  const showRowModal = id => modalRow.rowId === id;
  const isExpandedRow = id => expandedRow.rowId === id;
  const isSelectedRow = id =>
    selectedRow.rowIds.filter(s => s.id === id).length > 0;

  function selectThisRow(e) {
    console.log(e, 'this row has been selected');
    selectOptions[0].action(e);
  }

  return (
    <TableContainer>
      <HeaderRow>
        {rowOptions && <HeaderCell width={'3%'}></HeaderCell>}
        {headerColumns.map(header => (
          <HeaderCell
            key={`${header.name}-table-${header.dataName}`}
            columnName={header.dataName}
            text={header.text || header.dataName}
            width={header.width || `${(1 / headerColumns.length) * 100 - 3}%`}
            align={header.align}
          />
        ))}
      </HeaderRow>
      <TableBody>
        {hasDataRows(data) &&
          data.map((dataRow, idx) => {
            const ConditionalLink = props => {
              if (dataRow.rowLink) {
                debugger;
                return (
                  <Link to={dataRow.rowLink && dataRow.rowLink.text}>
                    {props.children}
                  </Link>
                );
              } else {
                return <React.Fragment>{props.children}</React.Fragment>;
              }
            };
            return (
              <React.Fragment key={`dataRow-${idx}`}>
                {rowExpand && (
                  <RowExpand
                    dataRow={dataRow}
                    expandedRow={expandedRow}
                    rowExpand={rowExpand}
                    setExpandedRow={setExpandedRow}
                  />
                )}
                {displayConfirmationRow(dataRow.id) ? (
                  <ConfirmationRow
                    confirmAction={confirmRow.action}
                    cancel={cancelConfirm}>
                    {confirmRow.message}
                  </ConfirmationRow>
                ) : (
                  <ConditionalLink to={dataRow.rowLink && dataRow.rowLink.text}>
                    <Row
                      dataRow={dataRow}
                      key={`${name}-table-row-id-${dataRow.id}`}
                      expanded={
                        isExpandedRow(dataRow.id) && expandedRow.expandedContent
                      }
                      setSelectedRow={
                        selectOptions ? e => selectThisRow(e) : null
                      }
                      selected={isSelectedRow(dataRow.id)}>
                      {rowOptions && (
                        <RowDropdown
                          dataRow={dataRow}
                          expandedRow={expandedRow}
                          setExpandedRow={setExpandedRow}
                          rowOptions={rowOptions}
                          setConfirmRow={setConfirmRow}
                          confirmRow={confirmRow}
                          setModalRow={setModalRow}
                          modalRow={modalRow}
                        />
                      )}

                      {hasDataValues(dataRow) &&
                        dataRow.data.map((dataCell, index) => {
                          const link =
                            dataRow.links.length &&
                            dataRow.links.find(cellLink => {
                              return (
                                cellLink.cell === headerColumns[index].dataName
                              );
                            });

                          return (
                            <Cell
                              key={`${index}-${dataCell}`}
                              data={dataCell}
                              width={
                                dataRow.width ||
                                headerColumns[index].width ||
                                `${(1 / headerColumns.length) * 100 - 3}%`
                              }
                              align={
                                dataRow.align || headerColumns[index].align
                              }
                              link={link && link.text}
                              buttonText={
                                link && link.buttonText ? link.buttonText : null
                              }
                            />
                          );
                        })}
                    </Row>
                  </ConditionalLink>
                )}
                {showRowModal(dataRow.id) && (
                  <TableModal>{modalRow.modal}</TableModal>
                )}
              </React.Fragment>
            );
          })}
      </TableBody>
    </TableContainer>
  );
};

export default Table;
