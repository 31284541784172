import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  height: ${(props) => props.height};
  border-radius: ${(props) => props.radius};
  margin: ${(props) => props.m};
`;

const Progress = styled.div`
  width: ${(props) => props.progress};
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  border-radius: ${(props) => props.radius};
`;

const ProgressBar = ({
  bg = '#5282f6',
  color = '#fff',
  progress = '50%',
  height = '0.8rem',
  radius = '0.5rem',
  m = '0',
}) => (
  <Container className="progress" height={height} radius={radius} m={m}>
    <Progress className="progress-bar" progress={progress} bg={bg} color={color} radius={radius}></Progress>
  </Container>
);

export default ProgressBar;
