import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "emotion-theming";
import { GlobalStateProvider } from "store";
import App from "app";
import defaultTheme from "components/UI/theme/defaultTheme";
import GlobalStyles from "components/UI/GlobalStyles";
import "components/UI/theme/index.scss";

const Root = () => {
  document.title = `Mailhouse`;

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <GlobalStateProvider>
        <App />
      </GlobalStateProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
