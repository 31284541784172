import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
  font-size: ${props => props.fontSize || "1.1rem"};
  padding: 0 1rem;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
`;

const TextWrap = styled.span`
  padding-left: 0.6rem;
  font-weight: 500;
  font-size: ${props => props.fontSize || "1.15rem"};
`;

const IconText = ({ icon, text, fontSize, color }) => {
  const theme = useTheme();
  return (
    <Wrapper fontSize={fontSize}>
      <FontAwesomeIcon icon={icon} color={color ? color : theme.colors.grey} />
      <TextWrap fontSize={fontSize}>{text}</TextWrap>
    </Wrapper>
  );
};

export default IconText;
