export const initState = {
  appLoading: false,
  appLoadingMessage: null,
  bs_user: {
    cs_token: localStorage.getItem("cs_token") || null,
    username: localStorage.getItem("cs_username") || null,
  },
  isAuthenticated: localStorage.getItem("cs_token") ? true : false,
  notifications: [],
  pageTitle: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SIGN_IN_SUCCESS":
      return {
        ...state,
        bs_user: action.payload,
        isAuthenticated: true,
      };
    case "USER_IS_AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: true,
      };
    case "LOG_OUT":
      return {
        ...state,
        isAuthenticated: false,
      };
    case "LOADING_DISABLED":
      return {
        ...state,
        appLoading: false,
        appLoadingMessage: null,
      };
    case "LOADING_ENABLED":
      return {
        ...state,
        appLoading: true,
        appLoadingMessage: action.payload,
      };
    case "SET_NOTIFICATION":
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case "CLEAR_NOTIFICATION":
      return {
        ...state,
        notifications: state.notifications
          .slice(0, action.payload)
          .concat(
            state.notifications.slice(
              action.payload + 1,
              state.notifications.length
            )
          ),
      };
    default:
      return state;
  }
};

export default reducer;
