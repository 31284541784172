import React from 'react';
import {Global, css} from '@emotion/core';

const GlobalStyles = () => (
  <Global
    styles={css`
      h1 {
        font-size: 4rem;
      }
      ,
      h2 {
        font-size: 3.2rem;
      }
      ,
      h3 {
        font-size: 2.8rem;
      }
      ,
      h4 {
        font-size: 2.4rem;
      }
      ,
      h5 {
        font-size: 2rem;
      }
      ,
      h6 {
        font-size: 1.6rem;
      }
      div,
      span,
      p,
      a,
      button,
      .btn {
        font-size: 1.6rem;
      }
      .pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        color: #5282f6;
        a {
          position: relative;
          float: left;
          padding: 6px 12px;
          margin-left: -1px;
          line-height: 1.42857143;
          color: #337ab7;
          text-decoration: none;
          background-color: #fff;
          border: 1px solid #ddd;
        }
        .active a {
          text-decoration: underline;
          border: 1px solid #ddd;
          background-color: #337ab7;
          border-color: #337ab7;
          color: #ffffff;
        }
        .active a:hover {
          color: #ffffff;
        }
        .disabled a {
          color: #777;
          cursor: not-allowed;
          background-color: #fff;
          border-color: #ddd;
        }
        .disabled a:hover {
          color: #777;
          cursor: not-allowed;
          background-color: #fff;
          border-color: #ddd;
        }
      }
    `}
  />
);

export default GlobalStyles;
