import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useTheme} from 'emotion-theming';
import ReactPaginate from 'react-paginate';
import ClipLoader from 'react-spinners/ClipLoader';
import APIService from 'services/backstrap/apiService';
import PageLayout from 'components/UI/Layout/PageLayout';
import Table from 'components/UI/Table';
import useTableData from 'components/UI/Table/useTableData';
import {PageTitle, Text} from 'components/UI/Typography';
import {LoadingContainer} from './styles';

const PAGE_SIZE = 25;

const CampaignDetails = props => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(true);
  const [campaignsTotal, setCampaignsTotal] = useState(true);
  const [offset, setOffset] = useState(0);
  const [state, setState] = useState({
    campaign: [],
    recipients: [],
    totalResults: 0,
    campaignId: props.match.params.id || props.location.campaign.id,
  });

  const [printJobs, setPrintJobs] = useState([]);

  const columns = [
    {
      name: 'address1',
      dataName: 'address1',
      text: 'Recipient Address',
      width: '15%',
    },
    {
      name: 'city',
      dataName: 'city',
      text: 'City',
      width: '10%',
    },
    {
      name: 'state_abbr',
      dataName: 'state_abbr',
      text: 'State',
      width: '5%',
    },
    {
      name: 'zip',
      dataName: 'zip',
      text: 'Zip',
      width: '10%',
    },
    {
      name: 'mail_status',
      dataName: 'mail_status',
      text: 'Status',
      width: '10%',
    },
    {
      name: 'mail_date',
      dataName: 'mail_date',
      text: 'SENT DATE',
      width: '10%',
      dataFormat: val => moment(val).format('MM-DD-YYYY'),
    },
    {
      name: 'fullname',
      dataName: 'fullname',
      text: 'Recipient Name',
      width: '35%',
    },
    {
      name: 'send_url',
      dataName: 'send_url',
      text: 'Send',
      width: '10%',
      align: 'center',
      link: row => {
        return {
          text: ':sendUrl',
          params: {
            ':sendUrl': row.send_url,
          },
          buttonText: `View in Send`,
        };
      },
    },
  ];

  const {tableData} = useTableData(columns, state.recipients);
  const theme = useTheme();
  useEffect(() => {
    const loadCampaigns = async () => {
      try {
        const response = await APIService.getCampaignSearch({
          campaign_id: state.campaignId,
        });
        const campaign = response.results[0];
        const campaignRecipients = await APIService.getCampaignRecipients(
          state.campaignId,
          {limit: PAGE_SIZE, offset: offset},
        );
        setCampaignsTotal(campaignRecipients.total_results);
        setState({
          ...state,
          recipients: campaignRecipients.results,
          campaign: campaign,
        });
        console.log('campaign', campaign);
        if (campaign.print_job_ids) {
          setPrintJobs([]);
          
          await Promise.all(campaign.print_job_ids.map(async printJobId => {
            var printJobDetails = await APIService.getPrintJobDetails(printJobId);
            printJobs.push(printJobDetails);
            setPrintJobs(printJobs);
          }));
        }
        
        setLoading(false);
      } catch (err) {
        console.log('[error loading campaigns]', err);
      }
    };
    loadCampaigns();
  }, [currentPage]);

  if (!loading) {
    return (
      <PageLayout props={props}>
        {state.campaign ? (
          <>
            <div className="campaign-meta mb-5">
              <PageTitle>{state.campaign.send_name}</PageTitle>
              <Text
                color={theme.colors.text}
                weight="900"
                size="1.75rem"
                m="0 1rem 0 0">
                Status:
              </Text>
              <Text color={theme.colors.text} weight="600" size="1.75rem">
                {state.campaign.mail_status}
              </Text>
            </div>
            {/*
              campaign details
          */}
            <div className="row mb-5">
              <div className="col-sm-4">
                <div className="item my-2">
                  <Text
                    color={theme.colors.text}
                    weight="900"
                    size="1.5rem"
                    m="0 1rem 0 0">
                    Print Job:
                  </Text>
                  <Text color={theme.colors.blue} weight="900" size="1.5rem">
                    {
                      printJobs.map(printJob => {
                        console.log('printJob', printJob);
                        return (
                          <a 
                            style={{paddingRight: 15}}
                            href={`/job-details/${printJob.id}`}>
                            {printJob.display_name}
                          </a>
                        );
                      })
                    }
                  </Text>
                </div>
                <div className="item my-2">
                  <Text
                    color={theme.colors.text}
                    weight="900"
                    size="1.5rem"
                    m="0 1rem 0 0">
                    Type:
                  </Text>
                  <Text color={theme.colors.text} weight="600" size="1.5rem">
                    {state.campaign.mail_type}
                  </Text>
                </div>
                <div className="item my-2">
                  <Text
                    color={theme.colors.text}
                    weight="900"
                    size="1.5rem"
                    m="0 1rem 0 0">
                    Sender:
                  </Text>
                  <Text color={theme.colors.text} weight="600" size="1.5rem">
                    {state.campaign.sender_name} {'<'}
                    <a href={`mailto:${state.campaign.sender_email}`}>
                      {state.campaign.sender_email}
                    </a>
                    {'>'}
                  </Text>
                </div>
                {/* {printJob.print_run_name && (
                  <div className="item my-2">
                    <Text
                      color={theme.colors.text}
                      weight="900"
                      size="1.5rem"
                      m="0 1rem 0 0">
                      Print Run:
                    </Text>
                    <Text color={theme.colors.text} weight="600" size="1.5rem">
                      {printJob.print_run_name}{' '}
                      {printJob.print_run_output && (
                        <a href={printJob.print_run_output}>
                          {'<'}Download Output{'>'}
                        </a>
                      )}
                    </Text>
                  </div>
                )} */}

                {/* {printJob.print_run_name && (
                  <div className="item my-2">
                    <Text
                      color={theme.colors.text}
                      weight="900"
                      size="1.5rem"
                      m="0 1rem 0 0">
                      Recovery Run Active:
                    </Text>
                    <Text color={theme.colors.text} weight="600" size="1.5rem">
                      {printJob.recovery_run ? 'Yes' : 'No'}{' '}
                      {printJob.recovery_files_processed && (
                        <span>- Recovery run processed</span>
                      )}
                    </Text>
                  </div>
                )} */}
              </div>

              <div className="col-sm-4">
                <div className="item my-2">
                  <Text
                    color={theme.colors.text}
                    weight="900"
                    size="1.5rem"
                    m="0 1rem 0 0">
                    Client:
                  </Text>
                  <Text color={theme.colors.text} weight="600" size="1.5rem">
                    {state.campaign.send_client}
                  </Text>
                </div>
                <div className="item my-2">
                  <Text
                    color={theme.colors.text}
                    weight="900"
                    size="1.5rem"
                    m="0 1rem 0 0">
                    Source:
                  </Text>
                  <Text color={theme.colors.text} weight="600" size="1.5rem">
                    {state.campaign.client_source}
                  </Text>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="item my-2">
                  <Text
                    color={theme.colors.text}
                    weight="900"
                    size="1.5rem"
                    m="0 1rem 0 0">
                    Mail by date:
                  </Text>
                  <Text color={theme.colors.text} weight="600" size="1.5rem">
                    {moment.utc(state.campaign.mail_date).format('MM/DD/YYYY')}
                  </Text>
                </div>
                <div className="item my-2">
                  <Text
                    color={theme.colors.text}
                    weight="900"
                    size="1.5rem"
                    m="0 1rem 0 0">
                    Created Date:
                  </Text>
                  <Text color={theme.colors.text} weight="600" size="1.5rem">
                    {moment(state.campaign.created_date).format('MM/DD/YYYY')}
                  </Text>
                </div>
              </div>
            </div>

            {/*
              recipient details
          */}
            {state.recipients && state.recipients.length > 0 ? (
              <Table
                name="campaigns"
                headerColumns={columns}
                data={tableData}></Table>
            ) : null}
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(campaignsTotal / PAGE_SIZE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={data => {
                setCurrentPage(data.selected);
                setOffset(Math.ceil(data.selected * PAGE_SIZE));
              }}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={currentPage}
            />
          </>
        ) : (
          <></>
        )}
      </PageLayout>
    );
  }

  return (
    <PageLayout props={props}>
      <LoadingContainer>
        <ClipLoader size={25} color={'#123abc'} />
      </LoadingContainer>
    </PageLayout>
  );
};

export default CampaignDetails;
