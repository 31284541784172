import React, { lazy } from "react";
import CampaignsJobsList from "screens/CampaignsJobsList";
import CampaignDetails from "screens/CampaignDetails";
import PreviewJob from "screens/PreviewJob";
import JobDetail from "screens/JobDetails/index";
import CreatePrintPage from "screens/CreatePrintPage";
import PreMergedCampaignsReport from "screens/PreMergedCampaignsReport";

export const privateRoutes = [
  // {
  //   path: "/private-route",
  //   component: lazy(() => import('screens/ModuleName')), //lazy load modules
  //   exact: false
  // }
];

export const publicRoutes = [
  {
    path: "/job-details/:id",
    component: JobDetail,
    exact: true,
  },
  {
    path: "/create-job",
    component: CreatePrintPage,
    exact: true,
  },
  {
    path: "/campaign-details/:id",
    component: CampaignDetails,
    exact: true,
  },
  {
    path: "/preview-job/:id",
    component: PreviewJob,
    exact: true,
  },
  {
    path: "/",
    component: CampaignsJobsList,
    exact: true,
  },
  {
    path: "/pre-merged-campaigns-report",
    component: PreMergedCampaignsReport,
    exact: true
  }
];
