import React from 'react';
import { Backdrop, Overlay } from './index';

const ModalContainer = ({ children }) => (
  <>
    <Backdrop />
    <Overlay>{children}</Overlay>
  </>
);

export default ModalContainer;
