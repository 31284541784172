import React from "react";
import styled from "@emotion/styled";
import { useGlobalState } from "store";
import { useTheme } from "emotion-theming";
import { AppHeader } from "./Header";
import Footer from "./Footer";
import SearchLogo from "../svg/SearchLogo";

const Layout = styled.div`
  margin: 0 auto -80px;
  min-height: 100%;
  padding-bottom: 120px;
`;

const PageLayout = props => {
  const [appState] = useGlobalState();
  const theme = useTheme();
  return (
    <>
      <Layout>
        <AppHeader
          history={props.props.history}
          theme={theme}
          app="Mailhouse"
          homeLink={"/"}
          pageTitle={appState.pageTitle !== null ? appState.pageTitle : ""}
          icon={<SearchLogo size={35} />}
        />
        <div className="container my-5">{props.children}</div>
      </Layout>
      <Footer left={<>© Symple 2020 | Privacy | Terms </>} />
    </>
  );
};

export default PageLayout;
