import React, { useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import BarLoader from "react-bar-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCheckCircle,
  faTimesCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { Text } from "../Typography";
import { Flex } from "components/UI/Layout";
import { useTheme } from "emotion-theming";

const UploadComponent = props => {
  const [state, setFileState] = React.useState({
    file: null,
    uploadState: "ready",
  });
  const theme = useTheme();
  const onDrop = useCallback(
    files => {
      let arr = [];

      for (let file of files) {
        let filePromise = new Promise(resolve => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = event => {
            let fileExt = file.type;
            if (fileExt === "" || fileExt === null || fileExt === undefined) {
              const tmp = file.path.split(".");
              fileExt = tmp[tmp.length - 1];
            }
            const obj = {
              file: file,
              body: event.target.result.split(",")[1],
              ext: fileExt,
            };
            resolve(obj);
          };
        });
        arr.push(filePromise);
      }

      Promise.all(arr)
        .then(fileContents => {
          props.onDrop(fileContents);
        })
        .catch(err => console.log(err));

      //setFileState({ ...state, uploadState: "uploading" });
    },
    [state.file]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    setFileState({ ...state, uploadState: props.uploadState });
  }, [props.uploadState]);

  return (
    <div
      className="upload-component-wrap w-100 align-items-center rounded text-center"
      {...getRootProps()}>
      <div className="upload-component">
        <input
          {...getInputProps()}
          multiple
          accept=".csv, .log, .Log, .txt, text/plain"
        />
        <div className="card-body my-5 d-flex flex-column align-items-center">
          {state.uploadState === "ready" && (
            <>
              <FontAwesomeIcon icon={faUpload} size="2x" />
              {isDragActive ? (
                <p>Drop file here</p>
              ) : (
                <p>
                  Upload or <u>browse.</u>
                </p>
              )}
              <small className="text-muted mt-1" onClick={props.onCancel}>
                Cancel
              </small>
            </>
          )}

          {state.uploadState === "uploading" && (
            <>
              <FontAwesomeIcon icon={faUpload} color={theme.colors.green} />
              <div className="w-25 rounded mt-3">
                <BarLoader color={theme.colors.green} height="2" />
              </div>
            </>
          )}

          {state.uploadState === "success" && (
            <Flex align="center" justify="center">
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={theme.colors.green}
              />
              <Text
                p={"0 0 0 1rem"}
                color={theme.colors.green}
                weight="300"
                size={"1.25rem"}>
                Success Uploading
              </Text>
            </Flex>
          )}

          {state.uploadState === "error" && (
            <Flex align="center" justify="center">
              <FontAwesomeIcon icon={faTimesCircle} color={theme.colors.red} />
              <Text color={theme.colors.red} weight="300" size={"1.25rem"}>
                Error Uploading
              </Text>
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadComponent;
