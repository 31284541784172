import React from "react";
import { useTheme } from "emotion-theming";
import { Options, Option } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const RowOptions = ({
  options,
  row,
  setConfirm,
  setModal,
  setExpanded,
  setExpandedRow,
  expanded,
}) => {
  const theme = useTheme();
  return (
    <React.Fragment key={`row-options-${row.id}`}>
      <div>
        <FontAwesomeIcon icon={faEllipsisV} color={theme.colors.text} />
      </div>
      <Options>
        {options.map(option => (
          <React.Fragment key={`option-name-${option.name}`}>
            {option.action && (
              <Option key={`option-action-${option.name}`}>
                {option.confirmMessage ? (
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      setConfirm(
                        () => option.action(row),
                        option.confirmMessage
                      );
                    }}
                    disabled={option.disabled}>
                    <span>
                      <i className={`fa fa-${option.icon}`}></i> {option.label}
                    </span>
                  </button>
                ) : (
                  <button
                    className="btn btn-link"
                    onClick={() => option.action(row)}
                    disabled={option.disabled}>
                    <i className={`fa fa-${option.icon}`}></i> {option.label}
                  </button>
                )}
              </Option>
            )}
            {option.link && (
              <Option key={`option-link-${option.name}`}>
                <button className="btn btn-link">{option.link(row)}</button>
              </Option>
            )}
            {option.modal && (
              <Option key={`option-modal-${option.name}`}>
                <button
                  className="btn btn-link"
                  onClick={() => {
                    setModal(option.modal(row));
                  }}
                  disabled={option.disabled}>
                  <span>
                    <i className={`fa fa-${option.icon}`}></i> {option.label}
                  </span>
                </button>
              </Option>
            )}
            {option.expanded && (
              <Option key={`option-expand-${option.name}`}>
                {expanded.rowId !== row.id ? (
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      setExpanded(option.expanded(row));
                    }}
                    disabled={option.disabled}>
                    <span>
                      <i className={`fa fa-expand`}></i> {option.label}
                    </span>
                  </button>
                ) : (
                  <button
                    className="btn btn-link"
                    onClick={() =>
                      setExpandedRow({ rowId: null, expandedContent: null })
                    }
                    disabled={option.disabled}>
                    <span>
                      <i className={`fa fa-compress`}></i> {option.label}
                    </span>
                  </button>
                )}
              </Option>
            )}
          </React.Fragment>
        ))}
      </Options>
    </React.Fragment>
  );
};

export default RowOptions;
