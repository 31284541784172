import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useGlobalState } from "store";
import { useTheme } from "emotion-theming";
import Form from "react-bootstrap/Form";
import { Button } from "components/UI/Button";
import APIService from "services/backstrap/apiService";
import TextInput from "components/UI/TextInput/TextInput";
import PageLayout from "components/UI/Layout/PageLayout";
import { PageTitle, Text } from "components/UI/Typography";
import DatePicker from "components/UI/Form/DatePicker";
import ClipLoader from 'react-spinners/ClipLoader';
import {LoadingContainer} from './styles';

const CreatePrintPageComponent = props => {
  const [appState, appDispatch] = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState();
  const [state, setState] = useState({
    postOffices: [],
    campaigns: props.location.campaigns ? props.location.campaigns : [],
    totalLetterCount: null,
    totalImpressions: null,
    maxImpressionSize: 20000,
    mailDate: null,
    selectedPostOffice: null,
    selectedCampaigns: props.location.campaigns ? props.location.campaigns : [],
  });

  useEffect(() => {
    APIService.getPostOffices()
      .then(res => {
        console.log(res, "post offices");
        return res;
      })
      .then(res => {
        setState({ ...state, postOffices: res });
        return res;
      })
      .then(res => getTotals())
      .catch(error => {
        console.log(error);
      });
    console.log(state);
  }, [props]);

  function toggleCampaign(cmp) {
    const selected = state.selectedCampaigns;
    const match = selected.filter(c => c.id === cmp.id);
    if (match.length) {
      console.log(match, "exists in selected array");
      const filtered = selected.filter(c => c.id !== cmp.id);
      setState({ ...state, selectedCampaigns: filtered });
    } else {
      console.log(match, "does not exists in selected array");
      setState({
        ...state,
        selectedCampaigns: [...state.selectedCampaigns, cmp],
      });
    }
  }

  function getTotals() {
    let letterTotals = 0;
    let impressionTotals = 0;
    state.selectedCampaigns.forEach(e => {
      letterTotals += e.letter_count;
      impressionTotals += e.impression_count;
    });
    console.log(letterTotals, impressionTotals);
    setState(prev => ({
      ...prev,
      totalLetterCount: letterTotals,
      totalImpressions: impressionTotals,
    }));
  }

  useEffect(() => {
    getTotals();
  }, [state.selectedCampaigns]);

  function determineMailClass(mailClass) {
    if (mailClass === "ERR") {
      return "First Class Mail - Electronic Return Receipt";
    } else if (mailClass === "GC") {
      return "First Class Mail - Green Card Return Receipt";
    } else {
      return "First Class Mail";
    }
  }

  function submitPrintJob(e) {
    e.preventDefault();

    const obj = {
      mail_date: state.mailDate,
      post_office_id: state.selectedPostOffice
        ? state.selectedPostOffice.id
        : null,
      max_impressions: state.maxImpressionSize,
      campaign_ids: state.selectedCampaigns.map(c => c.id),
      electronic_return_receipt:
        state.selectedCampaigns[0].mail_type === "ERR" ? true : false,
      mail_class:
        state.selectedCampaigns[0].mail_type === "FC"
          ? "FIRSTCLASS"
          : "CERTIFIED",
    };

    console.log("submitting print job:", obj);

    if (
      obj.mail_date !== null &&
      obj.post_office_id !== null &&
      obj.max_impressions !== null &&
      obj.campaign_ids.length > 0 &&
      obj.electronic_return_receipt !== null &&
      obj.mail_class !== null
    ) {
      setLoadingMessage("Please do not refresh view. This may take a while depending on the size of the job.");
      setLoading(true);
      APIService.submitPrintJob(obj)
        .then(res => {
          setLoadingMessage(null);
          setLoading(false);
          console.log(res);
          props.history.push(`/preview-job/${res.print_batch_id}`);
        })
        .catch(err => {
          setLoadingMessage(null);
          setLoading(false);
          console.log("error submitting printjob:", err);
          appDispatch({
            type: "SET_NOTIFICATION",
            payload: {
              type: "danger",
              message: `error submitting ${
                err && err.data
                  ? err.data.results.display_message
                  : "error submitting"
              }`,
              active: true,
            },
          });
        });
    } else {
      appDispatch({
        type: "SET_NOTIFICATION",
        payload: {
          type: "danger",
          message: "missing fields",
          active: true,
        },
      });
    }
  }
  const theme = useTheme();

  if (loading) {
    return (
      <PageLayout props={props}>
        <LoadingContainer>
          <ClipLoader size={25} color={'#123abc'} />
          <br/>
          <Text color={theme.colors.blue} weight="600" size="1.75rem">
            {loadingMessage}
          </Text>
        </LoadingContainer>
      </PageLayout>
    );
  }

  return (
    <PageLayout props={props}>
      {props.location.campaigns ? (
        <div>
          <div className="campaign-meta mb-5">
            <PageTitle>Create Print Job</PageTitle>
            <Text
              color={theme.colors.text}
              weight="900"
              size="1.75rem"
              m="0 1rem 0 0">
              Mail Class:
            </Text>
            <Text color={theme.colors.blue} weight="600" size="1.75rem">
              {determineMailClass(state.campaigns[0].mail_type)}
            </Text>
          </div>

          <div className="jobInfo" className="row mt-5">
            <div className="col-sm-6">
              <div className="mb-3">
                <div id="postOfficeHeader" className="mb-3">
                  <strong>Campaigns in Print Job:</strong>
                </div>
                <div id="postOffices">
                  {state.campaigns
                    ? state.campaigns.map((campaign, index) => (
                        <div key={index} className="formBasicCheckbox">
                          <Form.Check
                            type="checkbox"
                            className="form-check d-flex align-items-center"
                            label={`${campaign.send_name} - ${campaign.letter_count} letters `}
                            onClick={() => toggleCampaign(campaign)}
                            checked={
                              state.selectedCampaigns.filter(
                                c => c.id === campaign.id
                              ).length
                                ? true
                                : false
                            }
                          />
                        </div>
                      ))
                    : null}
                </div>
              </div>

              <div className="mb-3">
                <div id="totals">
                  <p>
                    <strong className="mr-2">
                      Total Letters in Print Job:
                    </strong>
                    <span>{state.totalLetterCount}</span>
                  </p>

                  <div>
                    <strong className="mr-2">
                      Total Impressions in Print Job:
                    </strong>
                    <span>{state.totalImpressions}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="row flex-column">
                <strong className="mb-3">Select Post Office:</strong>

                <div id="postOffices">
                  {(state.postOffices && state.postOffices.length) > 0 &&
                    state.postOffices.map((postOffice, index) => (
                      <Form.Group
                        key={index}
                        controlId="formBasicCheckbox"
                        className="d-flex m-0">
                        <Form.Check
                          type="radio"
                          onClick={() =>
                            setState(prev => ({
                              ...prev,
                              selectedPostOffice: postOffice,
                            }))
                          }
                          checked={
                            state.selectedPostOffice
                              ? state.selectedPostOffice.id === postOffice.id
                              : false
                          }
                        />

                        <label>{`${postOffice.city} ${postOffice.zip} - ${postOffice.address1}`}</label>
                      </Form.Group>
                    ))}
                </div>
              </div>

              <div className="row d-flex flex-column">
                <div id="mailDate" className="col-sm-6 px-0">
                  <strong className="mb-3">Mail Date: </strong>

                  <DatePicker
                    width={"100%"}
                    startDate={state.mailDate}
                    setDate={date => {
                      setState({ ...state, mailDate: date });
                    }}
                    disabledDates={"past"}
                  />
                </div>

                <div className="maxJobDropDown" className="col-sm-6 px-0">
                  <strong className="mb-3">Maximum Impression Size</strong>
                  <TextInput
                    type="number"
                    id="maxImpressionSize"
                    className=""
                    defaultValue={state.maxImpressionSize}
                    onChange={e =>
                      setState({ ...state, maxImpressionSize: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="buttonContainer">
            <div>
              <Button onClick={submitPrintJob}>Review Print Job</Button>
            </div>
          </div>
        </div>
      ) : (
        //redirect if no campaigns are selected
        <Redirect to="/" />
      )}
    </PageLayout>
  );
};

export default CreatePrintPageComponent;
