import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { useGlobalState } from "store";
import APIService from "services/backstrap/apiService";
import Toast from "components/UI/Toast/Toast";
import Checkbox from "components/UI/Checkbox/Checkbox";
import LogoSpinner from "components/UI/Progress/Spinner";

import { iconInputSubmit, sympleLogo } from "components/UI/Svgs";

const SignIn = props => {
  document.title = `Symple - Sign In`;

  const [appState, appDispatch] = useGlobalState();
  const [err, setErr] = useState(null);
  const [toast, toastState] = React.useState(true);
  const toggletoast = () => toastState(!toast);
  const [signInState, setSignInState] = useState({
    username: "",
    password: "",
    usernameSet: false,
    loading: false,
  });

  //animation
  const bgProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 1000,
    duration: 1500,
  });
  const animatedProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 1300,
    duration: 500,
  });

  //form related
  const inputClassName = `input-wrap ${signInState.usernameSet && `nbr`}`;
  const passwordClassName = `input-wrap nbl ${
    signInState.usernameSet ? "" : "opacity-0"
  }`;
  const handleSignInChange = e =>
    setSignInState({ ...signInState, [e.target.name]: e.target.value });
  const inputEl = useRef(null);
  const submitUsername = e => {
    e.preventDefault();
    setSignInState(prev => {
      return { ...prev, usernameSet: true };
    });
  };
  useEffect(() => {
    if (signInState.usernameSet === true) {
      inputEl.current.focus();
    }
  }, [signInState.usernameSet]);

  const submit = e => {
    e.preventDefault();
    setSignInState(prev => {
      return { ...prev, loading: true };
    });
    APIService.signIn(signInState.username, signInState.password)
      .then(res => {
        setSignInState({ ...signInState, loading: false });
        appDispatch({ type: "SIGN_IN_SUCCESS", payload: res });

        // ALLOW LOG-IN DIRECTLY FROM THE SEARCH API FOR LOCAL DEVELOPMENT
        // OTHERWISE USE SEND FOR PROJECT-WIDE SIGN-IN
        if (res.cs_token) {
          localStorage.setItem("cs_token", res.cs_token);
        } else if (res.cssearch_api_token) {
          localStorage.setItem("cs_token", res.cssearch_api_token);
        } else {
          localStorage.setItem("cs_token", null);
        }

        localStorage.setItem("cs_username", res.username);
        props.history.push("/");
      })
      .catch(err => {
        setErr(err.data.message);
        setSignInState({ ...signInState, loading: false });
      });
  };

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      appDispatch({ type: "LOADING_DISABLED" });
    }, 1000);
    return () => loadingTimeout;
  }, [appDispatch]);

  return (
    <>
      {!appState.isAuthenticated ? (
        <>
          <nav id="top-bar" className="m-0">
            <a className="logo-wrap">
              <p className="logo-text light">Symple</p>
            </a>
            <a onClick={() => props.history.push("/signup")}>
              <p className="logo-text light pointer">Get Symple ID</p>
            </a>
          </nav>

          <animated.div
            style={bgProps}
            className="container
            login-container
            full-center
            d-flex
            flex-column
            text-center
            position-relative">
            <animated.div style={animatedProps} className="header-wrap">
              {sympleLogo}
              <h4 className="my-4 light">Symply sign in.</h4>
            </animated.div>

            <animated.div
              style={animatedProps}
              className="d-flex flex-column justify-content-center">
              <div className="inputs">
                <div className={inputClassName}>
                  <input
                    className="form-control"
                    type="text"
                    onChange={handleSignInChange}
                    autoFocus
                    placeholder="Symple ID"
                    name="username"
                    value={signInState.username}
                    onKeyPress={ev => ev.key === "Enter" && submitUsername(ev)}
                  />
                  {!signInState.usernameSet && (
                    <button className="btn input-btn" onClick={submitUsername}>
                      {iconInputSubmit}
                    </button>
                  )}
                </div>

                <div className={passwordClassName}>
                  <input
                    className="form-control"
                    type="password"
                    ref={inputEl}
                    placeholder="Password"
                    onChange={handleSignInChange}
                    name="password"
                    value={signInState.password}
                    onKeyPress={ev => ev.key === "Enter" && submit(ev)}
                  />
                  <button className="btn input-btn" onClick={submit}>
                    {signInState.loading === true ? (
                      <LogoSpinner />
                    ) : (
                      iconInputSubmit
                    )}
                  </button>
                </div>
              </div>

              <div className="checkbox-wrap">
                <Checkbox
                  id="remember_me"
                  label="Stay signed in."
                  onClick={() => {}}
                  checked={true}
                />
              </div>

              <div className="fadeline"></div>

              <button className="btn btn-link btn-sm light">
                Remember SympleID or password.
              </button>
            </animated.div>
          </animated.div>
          {err ? (
            <Toast
              message={err}
              type="danger"
              active={toast}
              close={toggletoast}
            />
          ) : null}
        </>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default SignIn;
