import React from 'react';
import { Backdrop, ModalContainer } from './styled';

const ModalWrap = ({ children }) => (
  <Backdrop>
    <ModalContainer className="modal-container">{children}</ModalContainer>
  </Backdrop>
);

export default ModalWrap;
