import React, { useState } from "react";
import { useDatepicker, START_DATE } from "@datepicker-react/hooks";
import DatePickerContext from "./datePickerContext";
import Month from "./Month";

import "./Calendar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const CalendarSelect = props => {
  const {
    startDate,
    handleSelectDate,
    handleClose,
    width,
    disabledDates,
  } = props;

  const [date, setDate] = useState(startDate || new Date());

  const handleDateChange = e => {
    const date = e.startDate;
    setDate(date);
  };

  const {
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: date,
    focusedInput: START_DATE,
    onDatesChange: handleDateChange,
    numberOfMonths: 1,
    exactMinBookingDays: true,
  });

  return (
    <DatePickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}>
      <div
        className="calendar-component-wrap"
        style={{ width: width ? width : "125%" }}>
        <div className="card calendar-card d-flex align-items-center">
          <div className="calendar-close" onClick={handleClose}>
            &times;
          </div>
          <div className="d-flex w-75 justify-content-between">
            <button
              className="month-toggle back btn"
              type="button"
              onClick={goToPreviousMonths}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              className="month-toggle forward btn"
              type="button"
              onClick={goToNextMonths}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>

          <div
            className="calendar-wrap card-body"
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${activeMonths.length}, 100%)`,
              gridGap: "0 64px",
              justifyItems: "center",
            }}>
            {activeMonths.map(month => (
              <Month
                key={`${month.year}-${month.month}`}
                year={month.year}
                month={month.month}
                firstDayOfWeek={0}
                disabledDates={disabledDates}
              />
            ))}
          </div>
          <div className="d-flex calendar-footer mb-3 w-100 justify-content-end px-4">
            <button
              type="submit"
              className="btn btn-link btn-sm"
              onClick={() => handleSelectDate(date)}
              disabled={false}>
              Select Date
            </button>
          </div>
        </div>
      </div>
    </DatePickerContext.Provider>
  );
};

export default CalendarSelect;
