import React, { useState, useEffect } from "react";
import moment from "moment";
// import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "components/UI/Table/index";
import { Link } from "react-router-dom";
import APIService from "services/backstrap/apiService";
import useTableData from "components/UI/Table/useTableData";

const ExpandedJobsComponent = props => {
  const [state, setState] = useState({
    isCardView: false,
    data: [],
  });
  const columns = [
    {
      name: "Send_id",
      dataName: "send_id",
      text: "Send ID",
      width: "15%",
      link: row => {
        return {
          text: row.send_url,
        };
      },
    },
    {
      name: "send-name",
      dataName: "send_name",
      text: "Send name",
      width: "25%",
      link: row => {
        return {
          text: "/campaign-details/:campaignId",
          params: {
            ":campaignId": row.id,
          },
        };
      },
    },
    {
      name: "sender",
      dataName: "sender_email",
      text: "Sender",
      width: "15%",
    },
    {
      name: "stage",
      dataName: "mail_status",
      text: "Sender",
      width: "15%",
    },
    {
      name: "letters",
      dataName: "letter_count",
      text: "LETTERS",
      width: "15%",
    },
    {
      name: "send-date",
      dataName: "mail_date",
      text: "SEND DATE",
      width: "15%",
      dataFormat: val => moment(val).format("MM-DD-YYYY"),
    },
    // {
    //   name: "row-link",
    //   dataName: "rowLink",
    //   text: "Details",
    //   link: row => {
    //     return {
    //       text: "/campaign-details/:campaignId",
    //       params: {
    //         ":campaignId": row.id,
    //       },
    //     };
    //   },
    //   // link: row => (
    //   //   <Link
    //   //     to={{
    //   //       pathname: `/campaign-details/${row.id}`,
    //   //       campaign: row.original,
    //   //     }}>
    //   //     Details
    //   //   </Link>
    //   // ),
    //   // text: "details",
    // },
  ];

  const rowOptions = [
    {
      label: "details",
      name: "details",
      icon: "list",
      link: row => (
        <Link
          to={{
            pathname: `/campaign-details/${row.id}`,
            campaign: row.original,
          }}>
          Details
        </Link>
      ),
    },
  ];

  let { tableData } = useTableData(columns, state.data);

  useEffect(() => {
    console.log(props.data, "details expanded");
    if (props.data && props.details !== null) {
      APIService.getPrintJobs(props.data.id)
        .then(response => {
          console.log(response, "getPrintJobs");
          setState({ data: response }, () => {});
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);
  console.log(state.data);

  return <Table name="jobs" headerColumns={columns} data={tableData}></Table>;
};

export default ExpandedJobsComponent;
