import React from "react";
import { ENDPOINTS } from "config";
import { useGlobalState } from "store";
import request from "./request";
import queryString from "query-string";

//const [appState, appDispatch] = useGlobalState();

const signIn = (username, password) => {
  return request({
    url: `${ENDPOINTS.SIGN_IN}`,
    method: "POST",
    data: {
      username,
      password,
    },
  });
};

const getPostOffices = () => {
  return request({
    url: `${ENDPOINTS.GET_POST_OFFICES}`,
    method: "GET",
  });
};

const getCampaignSearch = params => {
  return request({
    url: `${ENDPOINTS.GET_CAMPAIGN_SEARCH}`,
    method: "GET",
    params,
  });
};

const toggleHiddenCampaign = ids => {
  return request({
    url: `${ENDPOINTS.TOGGLE_HIDDEN_CAMPAIGN}`,
    method: "POST",
    data: {
      campaign_ids: ids
    },
  });
};

const submitPrintJob = obj => {
  return request({
    url: `${ENDPOINTS.POST_PRINT_JOB}`,
    method: "POST",
    data: obj,
    // headers: {
    //   cs_token: token,
    // },
  });
};

const getPrintJobPreview = print_batch_id => {
  return request({
    url: `${ENDPOINTS.GET_PRINT_JOB_PREVIEW}`,
    method: "GET",
    params: { print_batch_id },
  });
};

const acceptPrintJob = obj => {
  return request({
    url: `${ENDPOINTS.ACCEPT_PRINT_JOB}`,
    method: "POST",
    data: obj,
  });
};

const searchPrintJobs = print_batch_id => {
  return request({
    url: `${ENDPOINTS.SEARCH_PRINT_JOBS}`,
    method: "GET",
    params: { print_batch_id },
  });
};

const getPrintJobs = print_job_id => {
  return request({
    url: `${ENDPOINTS.GET_PRINT_JOB}`,
    method: "GET",
    params: { print_job_id },
  });
};

const postMarkAllAsComplete = (obj) => {
  return request({
    url: `${ENDPOINTS.MARK_ALL_AS_COMPLETE}`,
    method: "POST",
    data: obj,
  });
}

const getPrintJobDetails = print_job_id => {
  return request({
    url: `${ENDPOINTS.GET_PRINT_JOB_DETAILS}`,
    method: "GET",
    params: { print_job_id },
  });
};

const getCampaignRecipients = (campaign_id, options) => {
  return request({
    url: `${ENDPOINTS.GET_CAMPAIGN_RECIPIENTS}?campaign_id=${campaign_id}&limit=${options.limit}`,
    method: "GET",
  });
};

const getPreMergedCampaigns = (options) => {
  return request({
    url: `${ENDPOINTS.GET_PRE_MERGED_CAMPAIGNS}?${queryString.stringify(options)}`,
    method: "GET",
  });
};

const getDownloadPreMergedCampaigns = (options) => {
  return request({
    url: `${ENDPOINTS.DOWNLOAD_PRE_MERGED_CAMPAIGNS}?${queryString.stringify(options)}`,
    method: "GET",
  });
}

const uploadRecoveryFile = obj => {
  return request({
    url: `${ENDPOINTS.UPLOAD_RECOVERY_FILE}`,
    method: "POST",
    data: {
      print_run_id: obj.print_run_id,
      recovery_file_type: obj.recovery_file_type,
      file: obj.file,
    },
  });
};

const printItems = ({ print_run_id, print_job_id }) => {
  return request({
    url: `${ENDPOINTS.PRINT_RUN}`,
    method: "POST",
    data: {
      print_run_id: print_run_id,
      print_job_id: print_job_id,
    },
  });
};

const submitRecoveryFiles = ({ print_run_id, print_job_id }) => {
  return request({
    url: `${ENDPOINTS.SUBMIT_RECOVERY_FILES}`,
    method: "POST",
    data: { print_run_id, print_job_id },
  });
};

const printRecoveryJob = print_job_id => {
  return request({
    url: `${ENDPOINTS.POST_RECOVERY_RUN}`,
    method: "POST",
    data: {print_job_id},
  });
};

const getRecoveryLetters = print_job_id => {
  return request({
    url: `${ENDPOINTS.GET_RECOVERY_LETTERS}?print_job_id=${print_job_id}`,
    method: "GET",
  });
};

const loadTruck = print_job_id => {
  return request({
    url: `${ENDPOINTS.LOAD_TRUCK}`,
    method: "POST",
    data: { print_job_id },
  });
};

const patchRecoveryLetter = obj => {
  return request({
    url: `${ENDPOINTS.INSERT_RECOVERY_LETTER}`,
    method: "PATCH",
    data: obj,
  });
};

const greenCardImpb = obj => {
  return request({
    url: `${ENDPOINTS.GREEN_CARD_IMPB}`,
    method: "PATCH",
    data: obj,
  });
};

const confirmDrop = ({ print_job_id, mailed_date }) => {
  return request({
    url: `${ENDPOINTS.CONFIRM_MAIL_DROP}`,
    method: "POST",
    data: { print_job_id, mailed_date },
  });
};

const APIService = {
  signIn,
  getPostOffices,
  getCampaignSearch,
  toggleHiddenCampaign,
  submitPrintJob,
  getPrintJobPreview,
  acceptPrintJob,
  searchPrintJobs,
  getPrintJobs,
  getCampaignRecipients,
  getPrintJobDetails,
  uploadRecoveryFile,
  submitRecoveryFiles,
  printRecoveryJob,
  printItems,
  getRecoveryLetters,
  loadTruck,
  patchRecoveryLetter,
  greenCardImpb,
  confirmDrop,
  postMarkAllAsComplete,
  getPreMergedCampaigns,
  getDownloadPreMergedCampaigns
};

export default APIService;
