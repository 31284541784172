import React, { useReducer, createContext, useContext } from 'react';
import reducer, { initState } from 'reducers/reducer'

const combineReducers = (...reducers) => (state, action) => {
  for(let i=0;i<reducers.length;i++) 
    state = reducers[i](state, action)
  return state;
}
const rootReducer = combineReducers(reducer) 
//add reducers here if needed, e.g. combineRdeucers(reducer, dashboardReducer);
const rootInitialState = Object.assign({}, initState) 
//add init state of each reducer, Object.assign({}, initState, dashboardInitState);

//Global Provider
const GlobalStore = createContext(initState);
const GlobalStateProvider = (props) => {
    const globalAppStore = useReducer(rootReducer, rootInitialState)
    const { Provider } = GlobalStore
    return (
        <Provider value={globalAppStore}>
            {props.children}
        </Provider>
    )
}
const useGlobalState = () => {
    const context = useContext(GlobalStore)
    if (context === undefined) {
      throw new Error('useGlobalState error')
    }
    return context
}

export { GlobalStore, GlobalStateProvider, useGlobalState }