import styled from 'styled-components'

const LoadingContainer = styled.section`
  min-height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export {
  LoadingContainer,
}
