import React, { useState, useEffect } from "react";
import { useGlobalState } from "store";
import { useTheme } from "emotion-theming";
import PageLayout from "components/UI/Layout/PageLayout";

import APIService from "services/backstrap/apiService";
import CampaignCard from "./CampaignCard";
import { PageTitle, Text } from "components/UI/Typography";
import { Flex } from "components/UI/Layout";
import IconText from "components/UI/IconText";
import TextInput from "components/UI/Form/TextInput";
import { Button } from "components/UI/Button";
import { Card } from "components/UI/Cards";
import ModalWrap from "components/UI/ModalWrap";
import DropzoneUpload from "components/UI/DropzoneUpload";
import SaveInput from "components/UI/Form/SaveInput";
import DatePicker from "components/UI/Form/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "./ProgressBar";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";

import styled from "@emotion/styled";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import {
  faCircle,
  faCircleNotch,
  faDotCircle,
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const SaveInputWrapper = styled.div`
&&& {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  background: ${props => (props.bg ? props.bg : "#e3ebff")};
  padding: 0.5rem 2rem;
  .search-text-btn-wrap {
    display: flex;
    align-items: center;
  }
  .search-text-input {
    margin: 0 !important;
    padding-right: 10px;
    input {
      background: white !important;
      font-size: 1em;
      ::placeholder,
      ::-ms-input-placeholder {
        color: #8f95a7 !important;
      }
      :-ms-input-placeholder {
        color: #8f95a7 !important;
      }
    }
  }
}
`;

const JobDetailComponent = props => {
  const [appState, appDispatch] = useGlobalState();
  const [state, setState] = useState({
    data: [],
    progress: 0,
    stateToggle: null,
    hasRecoveryErrors: false,
    dropDate: null,
    recovery_letters: [],
  });

  const [uploadState, setUploadState] = useState({
    status: "ready",
    files: [],
    selectedFiles: [],
    requiredTypes: null,
  });

  const [recItems, setRecItems] = useState({
    new: null,
    items: [],
    editing: null,
    require_receipt: false,
    receipt_number: "",
    editingGC: null,
  });

  const [newRecoveryItem, setNewRecoveryItem] = useState({
    tray_number: "",
    piece_number: ""
  });

  const theme = useTheme();

  function setJobData(res) {
    const status = res.mail_status;
    let progress = 0;
    if (status) {
      switch (status) {
        case "ACCEPTED":
          progress = 5;
          break;
        case "PRESORT":
          progress = 15;
          break;
        case "PREPRESS":
          progress = 30;
          break;
        case "PRINTING":
          progress = 45;
          break;
        case "FINISHING":
          progress = 60;
          break;
        case "LOADING":
          progress = 75;
          break;
        case "IN-TRANSIT":
          progress = 90;
          break;
        case "MAILED":
          progress = 100;
          break;
        default:
          break;
      }
    }
    setState({ ...state, progress: progress, data: res });
  }

  const status = state.data.mail_status;

  useEffect(() => {
    APIService.getPrintJobDetails(props.match.params.id)
      .then(res => {
        console.log(res, "getPrintJobDetails");
        setJobData(res);
      })
      .catch(err => console.log(err));
  }, []);

  function stateToggle(status) {
    setState({
      ...state,
      stateToggle: status,
      data: { ...state.data, mail_status: status },
    });
  }

  useEffect(() => {
    //temporary, take this out after done testing
    let progress = 0;
    if (state.stateToggle) {
      switch (state.stateToggle) {
        case "ACCEPTED":
          progress = 5;
          break;
        case "PRESORT":
          progress = 15;
          break;
        case "PREPRESS":
          progress = 30;
          break;
        case "PRINTING":
          progress = 45;
          break;
        case "FINISHING":
          progress = 60;
          break;
        case "LOADING":
          progress = 75;
          break;
        case "IN-TRANSIT":
          progress = 90;
          break;
        case "MAILED":
          progress = 100;
          break;
        default:
          break;
      }
    }
    setState({ ...state, progress: progress });
  }, [state.stateToggle]);

  const printItems = () => {
    if (state.data.current_print_run_id && state.data.print_batch_id) {
      const obj = {
        print_run_id: state.data.current_print_run_id,
        print_job_id: state.data.id,
      };

      console.log(obj, "rintrunid");
      APIService.printItems(obj)
        .then(res => {
          console.log(res, "printItems");
          stateToggle("PRINTING");
        })
        .catch(err => {
          console.log(err, "error creatign Print Items");
          appDispatch({
            type: "SET_NOTIFICATION",
            payload: {
              type: "danger",
              message: `error printing items`,
              active: true,
            },
          });
        });
    }
  };

  const uploadRecoveryFile = files => {
    let success = [];
    let types;
    const mailClass = state.data.mail_class;
    if (
      mailClass === "CERTIFIED" &&
      state.data.electronic_return_receipt === false
    ) {
      //CERTIFIED MAIL CLASS: all 4 recovery types are required
      let requiredTypes = {
        csv_1: null,
        csv_2: null,
        camera_log_1_url: null,
        camera_log_2_url: null,
      };

      for (let file of files) {
        let recovery_type;
        const f = file.file;
        const ext = file.ext;

        //determine recovery file type
        if (ext === "csv" || ext === "text/csv") {
          if (requiredTypes.camera_log_1_url) {
            recovery_type = "csv_2";
            requiredTypes.csv_2 = true;
          } else {
            recovery_type = "csv_1";
            requiredTypes.csv_1 = true;
          }
        } else if (
          ext === "log" ||
          ext === "Log" ||
          ext === "txt" ||
          ext === "text/plain"
        ) {
          if (requiredTypes.camera_log_1_url) {
            recovery_type = "camera_log_2_url";
            requiredTypes.camera_log_2_url = true;
          } else {
            recovery_type = "camera_log_1_url";
            requiredTypes.camera_log_1_url = true;
          }
        } else {
          //return invalid
          appDispatch({
            type: "SET_NOTIFICATION",
            payload: {
              type: "danger",
              message: `invalid file ${f.name}`,
              active: true,
            },
          });
        }

        //create object for upload
        if (recovery_type) {
          const obj = {
            name: f.name,
            print_run_id: state.data.current_print_run_id,
            recovery_file_type: recovery_type,
            file: {
              body: file.body,
              ext: file.ext === "text/plain" ? "txt" : file.ext,
            },
          };
          // console.log(obj, "POST to /v1/printrun/upload_recovery_file");
          success.push(obj);
        }
      }
    } else {
      //FIRST CLASS MAIL: 3 recovery types are required
      let requiredTypes = {
        csv_1: null,
        camera_log_1_url: null,
        camera_log_2_url: null,
      };

      for (let file of files) {
        let recovery_type;
        const f = file.file;
        const ext = file.ext;

        //determine recovery file type
        if (ext === "csv" || ext === "text/csv") {
          recovery_type = "csv_1";
          requiredTypes.csv_1 = true;
        }
        else if (
          ext === "log" ||
          ext === "Log" ||
          ext === "txt" ||
          ext === "text/plain"
        ) {
          if (requiredTypes.camera_log_1_url) {
            recovery_type = "camera_log_2_url";
            requiredTypes.camera_log_2_url = true;
          } else {
            recovery_type = "camera_log_1_url";
            requiredTypes.camera_log_1_url = true;
          }
        } else {
          //return invalid
          appDispatch({
            type: "SET_NOTIFICATION",
            payload: {
              type: "danger",
              message: `invalid file ${f.name}`,
              active: true,
            },
          });
        }

        //create object for upload
        if (recovery_type) {
          const obj = {
            name: f.name,
            print_run_id: state.data.current_print_run_id,
            recovery_file_type: recovery_type,
            file: {
              body: file.body,
              ext: file.ext === "text/plain" ? "txt" : file.ext,
            },
          };
          // console.log(obj, "POST to /v1/printrun/upload_recovery_file");
          success.push(obj);
        }
      }

      types = requiredTypes;
    }

    Promise.all(success)
      .then(files => {
        console.log(files, "yeah");
        console.log(types, "ok");
        setUploadState({
          ...uploadState,
          requiredTypes: types,
          selectedFiles: files,
          status: "uploading",
        });
        return files;
      })
      .catch(err => console.log(err, "error returning uploads"));
  };

  function showRequiredTypes(types) {
    let arr = [];
    for (const [key, value] of Object.entries(types)) {
      arr.push(
        <div key={value}>
          <FontAwesomeIcon
            icon={value ? faCheckCircle : faTimesCircle}
            color={value ? theme.colors.green : theme.colors.red}
          />{" "}
          {key}
        </div>
      );
    }
    return arr;
  }

  useEffect(() => {
    console.log(`${uploadState.selectedFiles.length} recovery files selected`);
    if (uploadState.selectedFiles && uploadState.selectedFiles.length > 0) {
      let arr = [];

      uploadState.selectedFiles.forEach(file => {
        let filePromise = new Promise(resolve => {
          console.log(file, "POST to /v1/printrun/upload_recovery_file");
          APIService.uploadRecoveryFile(file)
            .then(res => {
              console.log(res, "successfully uploaded");
              resolve(res);
            })
            .catch(err => {
              console.log(err, "error uploading file");
              appDispatch({
                type: "SET_NOTIFICATION",
                payload: {
                  type: "danger",
                  message: `error submitting ${
                    err && err.data ? err.data.message : "error submitting"
                  }`,
                  active: true,
                },
              });
            });
        });
        arr.push(filePromise);
      });

      Promise.all(arr)
        .then(files =>
          setUploadState({ ...uploadState, files: files, status: "success" })
        )
        .catch(err => console.log(err, "erororro"));
    }
  }, [uploadState.selectedFiles]);

  const submitRecoveryFiles = () => {
    const obj = {
      print_run_id: state.data.current_print_run_id,
      print_job_id: state.data.id,
    };
    APIService.submitRecoveryFiles(obj)
      .then(res => {
        console.log(res, "success submitting recovery files");
        setState({
          ...state,
          data: {
            ...state.data,
            recovery_files_processed: true,
            recovery_letters: res.recovery_letters,
          },
        });
        appDispatch({
          type: "SET_NOTIFICATION",
          payload: {
            type: "success",
            message: "success submitting recovery file",
            active: true,
          },
        });
      })
      .catch(err => {
        console.log(err, "error submitting recovery files");
        appDispatch({
          type: "SET_NOTIFICATION",
          payload: {
            type: "danger",
            message:
              err && err.data
                ? err.data.message
                : "error submitting recovery file",
            active: true,
          },
        });
      });
  };

  function getRecoveryLetters() {
    //getRecoveryLetters
    if (state.data && state.data.id) {
      APIService.getRecoveryLetters(state.data.id)
        .then(res => {
          console.log(res, "recovery letters");
          setState({ ...state, recovery_letters: res });
          setRecItems({ ...recItems, items: res });
        })
        .catch(err => {
          console.log(err, "error getting recovery letters");
        });
    }
  }

  useEffect(() => {
    getRecoveryLetters();
  }, [state.data.recovery_files_processed]);

  const saveRecoveryItem = () => {
    if (newRecoveryItem.piece_number != "" && saveRecoveryItem.tray_number != "") {
      let obj = {
        print_run_id: state.data.current_print_run_id,
        insert_result: "UserMarkedForReprint",
        piece_number: newRecoveryItem.piece_number,
        tray_number: newRecoveryItem.tray_number,
      };
      console.log('obj', obj);
      APIService.patchRecoveryLetter(obj)
        .then(res => {
          setNewRecoveryItem({
            piece_number: "",
            tray_number: ""
          })
          console.log(res, "success patching recovery letter");
          getRecoveryLetters();
        })
        .catch(err => {
          console.log(err, "error patching recovery letter");
          appDispatch({
            type: "SET_NOTIFICATION",
            payload: {
              type: "danger",
              message:
                err && err.data
                  ? `${err.data.message} - ${err.data.display_message}`
                  : "error patching recovery letter",
              active: true,
            },
          });
        });
    }
  };

  function printRecoveryJob() {
    if (state.data.id) {
      APIService.printRecoveryJob(state.data.id)
        .then(res => {
          console.log(res, "printRecoveryJob");
          APIService.getPrintJobDetails(props.match.params.id)
          .then(res => {
            console.log(res, "getPrintJobDetails");
            setJobData(res);
          })
          .catch(err => console.log(err));
        })
        .catch(err => {
          appDispatch({
            type: "SET_NOTIFICATION",
            payload: {
              type: "danger",
              message:
                err && err.data
                  ? `${err.data.message} - ${err.data.display_message}`
                  : "error printing recovery job",
              active: true,
            },
          });
          console.log(err, "error creatign Print Items");
        });
    }
  }

  function markRecoveryItemReprint(item) {
    setRecItems({ ...recItems, editing: item });
  }

  function insertResult(result) {
    console.log(result);
    let obj = {
      print_job_id: state.data.id,
      letter_id: recItems.editing.id,
      insert_result: result,
    };

    console.log(obj, `PATCH /v1/letter/insert_result`);
    APIService.patchRecoveryLetter(obj)
      .then(res => {
        console.log(res, "success inserting result");
        appDispatch({
          type: "SET_NOTIFICATION",
          payload: {
            type: "success",
            message: "updated item",
            active: true,
          },
        });
        setRecItems({ ...recItems, editing: null });
        APIService.getRecoveryLetters(state.data.id)
          .then(res => {
            console.log(res, "recovery letters");
            setState({ ...state, recovery_letters: res });
            setRecItems({ ...recItems, items: res, editing: null });
          })
          .catch(err => {
            console.log(err, "error getting recovery letters");
            setRecItems({ ...recItems, editing: null });
          });
      })
      .catch(err => {
        console.log(err, "error inserting result");
        appDispatch({
          type: "SET_NOTIFICATION",
          payload: {
            type: "danger",
            message:
              err && err.data
                ? `${err.data.message} - ${err.data.display_message}`
                : "error patching recovery letter",
            active: true,
          },
        });
      });
  }

  function recordReceipt(item) {
    setRecItems({
      ...recItems,
      editingGC: item,
      require_receipt: true,
    });
  }

  function saveReceiptNumber() {
    if (recItems.receipt_number !== "") {
      let gc = {
        green_card_impb: recItems.receipt_number,
        letter_id: recItems.editingGC.id,
      };
      console.log(gc, `PATCH /v1/letter/green_card_impb`);
      APIService.greenCardImpb(gc)
        .then(res => {
          console.log(res, "success submitting gc receipt");
          //setRecItems({ ...recItems, editingGC: null });
          appDispatch({
            type: "SET_NOTIFICATION",
            payload: {
              type: "success",
              message: "updated item",
              active: true,
            },
          });
        })
        .then(() => {
          APIService.getRecoveryLetters(state.data.id)
            .then(res => {
              console.log(res, "recovery letters");
              setState({ ...state, recovery_letters: res });
              setRecItems({ ...recItems, items: res, editingGC: null });
            })
            .catch(err => {
              console.log(err, "error getting recovery letters");
              setRecItems({ ...recItems, editingGC: null });
            });
        })
        .catch(err => {
          console.log(err, "error submitting gc receipt");
          appDispatch({
            type: "SET_NOTIFICATION",
            payload: {
              type: "danger",
              message:
                err && err.data
                  ? `${err.data.message} - ${err.data.display_message}`
                  : "error submitting receipt",
              active: true,
            },
          });
        });
    } else {
      appDispatch({
        type: "SET_NOTIFICATION",
        payload: {
          type: "danger",
          message: "Please provide receipt number",
          active: true,
        },
      });
    }
  }

  const loadTruck = () => {
    APIService.loadTruck(state.data.id)
      .then(res => {
        console.log(res, "success loading truck");
        stateToggle("LOADING");
        //stateToggle("FINISHING");
      })
      .catch(err => {
        console.log(err, "error loading truck");
        appDispatch({
          type: "SET_NOTIFICATION",
          payload: {
            type: "danger",
            message: err && err.data ? err.data.message : "error loading truck",
            active: true,
          },
        });
      });
  };

  const confirmDrop = () => {
    if (state.dropDate) {
      const obj = {
        print_job_id: state.data.id,
        mailed_date: state.dropDate,
      };
      APIService.confirmDrop(obj)
        .then(res => {
          console.log(res, "succes confirming dropdate");
          stateToggle("MAILED");
        })
        .catch(err => {
          console.log(err, "error confirming dropdate");
          appDispatch({
            type: "SET_NOTIFICATION",
            payload: {
              type: "danger",
              message:
                err && err.data
                  ? `${err.data.message} - ${err.data.display_message}`
                  : "error confirming drop date",
              active: true,
            },
          });
        });
    }
  };

  function disableGreenCardButton(item) {
    console.log(item, "the item");
    console.log(state.data.mail_class, "themail class");
    if (
      state.data.mail_class === "CERTIFIED" &&
      state.data.electronic_return_receipt === false
    ) {
      if (item.green_card_imbp) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const page = (
    <PageLayout props={props}>
      <div className="campaign-meta mb-5">
        <PageTitle>{`${state.data.display_name} - ${state.data.id}`}</PageTitle>
      </div>

      <ProgressBar status={status} progress={state.progress} />

      <div className="content-wrap mt-5">
        <div className="row">
          <div className="col-sm-9">
            <Text color={theme.colors.text} weight="900" size="2rem">
              Campaign Status: {status}
            </Text>
            {state.data && status === "ACCEPTED" && (
              <>
                <p>
                  The campaign has been accepted. Check back later to see if the
                  status has updated.
                </p>
                <Button onClick={() => stateToggle("PRESORT")}>
                  Next Step
                </Button>
              </>
            )}

            {state.data && status === "PRESORT" && (
              <>
                <p>
                  The campaign is currently under presort. Check back later to
                  see if the status has updated.
                </p>
                <Button onClick={() => stateToggle("PREPRESS")}>
                  Next Step
                </Button>
              </>
            )}

            {state.data && status === "PREPRESS" && (
              <>
                {state.data.mail_stage_complete ? (
                  <>
                    <p>
                      The campaign has been prepressed. When you are ready, you
                      may begin printing
                    </p>
                    <Button onClick={printItems}>Print Items</Button>
                  </>
                ) : (
                  <p>Mail stage is not complete</p>
                )}
              </>
            )}

            {/* STATUS: PRINTING & RECOVERY UPLOAD */}

            {state.data && status === "PRINTING" ? (
              !state.data.recovery_files_processed ? (
                <>
                <div className="row">
                  <div className="col-sm-9">
                    <DropzoneUpload
                      onDrop={uploadRecoveryFile}
                      uploadState={uploadState.status}
                      onCancel={e => {
                        e.stopPropagation();
                      }}
                    />
                  </div>
                  <div className="col-sm-3">
                    {uploadState.requiredTypes ? (
                      <>
                        <Text
                          color={theme.colors.text}
                          weight="900"
                          m={"0 0 1rem"}
                          size="1.25rem">
                          Required files
                        </Text>
                        {showRequiredTypes(uploadState.requiredTypes)}
                      </>
                    ) : null}
                  </div>
                  {uploadState.files && uploadState.files.length ? (
                    <div className="d-flex flex-column w-100 px-4 mt-3">
                      {uploadState.files.map((f, i) => (
                        <div
                          key={i}
                          className="d-flex flex-row justify-content-between">
                          <span>
                            <strong>{f.name}</strong>
                          </span>
                          {f.csv_2 && (
                            <span>
                              <a
                                href={f.csv_2}
                                noref="true"
                                target="_blank">
                                camera count
                              </a>
                            </span>
                          )}
                          {f.camera_log_1_url && (
                            <span>
                              <a
                                href={f.camera_log_1_url}
                                noref="true"
                                target="_blank">
                                camera log 1
                              </a>
                            </span>
                          )}

                          {f.camera_log_2_url && (
                            <span>
                              <a
                                href={f.camera_log_2_url}
                                noref="true"
                                target="_blank">
                                camera log 2
                              </a>
                            </span>
                          )}

                          {f.csv_1 && (
                            <span>
                              <a
                                href={f.csv_1}
                                noref="true"
                                target="_blank">
                                green card numbers
                              </a>
                            </span>
                          )}
                        </div>
                      ))}
                      <div className="d-flex align-center justify-content-center mt-4">
                        <Button onClick={submitRecoveryFiles}>
                          Submit Recovery Files
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="row">
                  <div className="col-sm-9" style={{textAlign: 'center'}}>
                    <Button
                      m="0 1rem"
                      className="item-actions w-30"
                      onClick={() => {
                        APIService.postMarkAllAsComplete({print_job_id: state.data.id})
                        .then(() => {
                          APIService.getPrintJobDetails(props.match.params.id)
                          .then(res => {
                            console.log(res, "getPrintJobDetails");
                            setJobData(res);
                          })
                          .catch(err => console.log(err));
                        })
                        .catch(err => {
                          console.log(err, "error marking print job as complete");
                          appDispatch({
                            type: "SET_NOTIFICATION",
                            payload: {
                              type: "danger",
                              message: `error marking print job as complete`,
                              active: true,
                            },
                          });
                        });
                      }}
                      disabled={false}>
                      MARK ALL AS COMPLETE
                    </Button>
                  </div>
                </div>
                </>
              ) : (
                <>
                  <p>
                    The items below need attention. Additionally, you may
                    manually add items by piece number.
                  </p>

                  <SaveInputWrapper bg={props.bg}>
                    <strong>Add item by piece number</strong>
                    <div className="search-text-btn-wrap">
                      <TextInput 
                        value={newRecoveryItem.tray_number}
                        placeholder="Tray Number"
                        className="search-text-input" 
                        style={{paddingRight: 10}}
                        onChange={e => {
                          setNewRecoveryItem({
                            ...newRecoveryItem,
                            tray_number: e.target.value
                          });
                        }} 
                      />
                      <TextInput 
                        value={newRecoveryItem.piece_number}
                        placeholder="Piece Number"
                        className="search-text-input" 
                        onChange={e => {
                          setNewRecoveryItem({
                            ...newRecoveryItem,
                            piece_number: e.target.value
                          });
                        }} 
                      />
                      <Button  
                        p="0.5rem 1rem"
                        onClick={() => {
                          saveRecoveryItem();
                        }}>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Button>
                    </div>
                  </SaveInputWrapper>

                  {state.data.recovery_letters &&
                  state.data.recovery_letters.length > 0 ? (
                    state.data.recovery_letters.map(letter => (
                      <li>{letter.id}</li>
                    ))
                  ) : (
                    <>
                      {recItems.items && recItems.items.length ? (
                        <>
                          <div className="recovery-letter-item-header d-flex flex-row justify-content-between border-bottom">
                            <div className="w-20 font-weight-bold">ID</div>
                            <div className="w-10 font-weight-bold">
                              Piece No.
                            </div>
                            <div className="w-30 font-weight-bold text-center">
                              Container Info
                            </div>
                            <div className="w-30 font-weight-bold text-center">
                              Edit Status
                            </div>
                            {state.data.mail_class === "CERTIFIED" &&
                            state.data.electronic_return_receipt === false ? (
                              <div className="w-10 font-weight-bold text-center">
                                Receipt #
                              </div>
                            ) : null}
                          </div>
                          {recItems.items.map(item => (
                            <div
                              className="recovery-letter-item d-flex flex-row justify-content-between align-items-center py-4"
                              key={item.id}>
                              <div className="item-id w-20">
                                <strong>Item {item.id}</strong>
                              </div>

                              <div className="item-piece w-10">
                                {item.container_piece_number}
                              </div>

                              <div className="item-piece w-30">
                                Container level {item.container_level} - Count{" "}
                                {item.container_piece_count} #
                                {item.container_piece_number}
                              </div>

                              <Button
                                m="0 1rem"
                                className="item-actions w-30"
                                onClick={() => markRecoveryItemReprint(item)}
                                disabled={
                                  item.mail_class === "CERTIFIED" &&
                                  item.electronic_return_receipt === false
                                    ? !item.green_card_impb
                                      ? true
                                      : false
                                    : false
                                }>
                                <FontAwesomeIcon
                                  icon={
                                    item.insert_result === "Error" ||
                                    item.insert_result === "Mismatch" ||
                                    item.insert_result === "NotConfirmed" ||
                                    item.insert_result ===
                                      "UserMarkedForReprint" ||
                                    item.insert_result === "NotAttempted"
                                      ? faExclamationTriangle
                                      : faCheckCircle
                                  }
                                  color={
                                    item.insert_result === "Error" ||
                                    item.insert_result === "Mismatch" ||
                                    item.insert_result === "NotConfirmed" ||
                                    item.insert_result ===
                                      "UserMarkedForReprint" ||
                                    item.insert_result === "NotAttempted"
                                      ? theme.colors.yellow
                                      : theme.colors.green
                                  }
                                />
                                {item.insert_result}
                              </Button>

                              {item.mail_class === "CERTIFIED" &&
                              item.electronic_return_receipt === false ? (
                                !item.green_card_impb ? (
                                  <Button
                                    m="0"
                                    className="item-actions w-10"
                                    onClick={() => recordReceipt(item)}>
                                    Add
                                  </Button>
                                ) : (
                                  <div className="item-actions w-10 text-center">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      color={theme.colors.green}
                                    />
                                  </div>
                                )
                              ) : null}
                            </div>
                          ))}
                        </>
                      ) : (
                        <strong>No recovery letters</strong>
                      )}
                    </>
                  )}

                  {recItems.items && recItems.items.length ? (
                    <div className="my-5 d-flex align-items-center justify-content-center w-100">
                      <Button onClick={printRecoveryJob} m="0 0 2rem">
                        Print Recovery Job
                      </Button>
                    </div>
                  ) : null}

                  <Card>
                    <div className="d-flex justify-content-between align-items-center w-100 px-5">
                      <div>
                        <Text
                          color={theme.colors.text}
                          weight="900"
                          size="2rem">
                          Nothing to add?
                        </Text>
                        <p className="m-0">Proceed to the next step.</p>
                      </div>
                      <Button
                        onClick={loadTruck}
                        m="0"
                        bg={theme.colors.green}
                        color={theme.colors.fg}>
                        Load Truck
                      </Button>
                    </div>
                  </Card>
                </>
              )
            ) : null}

            {recItems.editing && (
              <ModalWrap>
                <Card width="50%" wrapClass="p-5">
                  <div className="d-flex align-items-center justify-content-between mb-5">
                    <h3>Insert Result</h3>
                    <FontAwesomeIcon
                      icon={faTimes}
                      color={theme.colors.text}
                      onClick={() =>
                        setRecItems({ ...recItems, editing: null })
                      }
                    />
                  </div>

                  <div className="row">
                    <div className="col-sm-6 d-flex flex-column flex-1">
                      <Button
                        onClick={() => insertResult("UserMarkedForReprint")}
                        m="0 0 1rem">
                        UserMarkedForReprint
                      </Button>
                      <Button
                        onClick={() => insertResult("UserConfirmed")}
                        m="0 0 1rem">
                        UserConfirmed
                      </Button>
                      <Button
                        onClick={() => insertResult("NotConfirmed")}
                        m="0 0 1rem">
                        NotConfirmed
                      </Button>
                      <Button
                        onClick={() => insertResult("NotAttempted")}
                        m="0 0 1rem">
                        NotAttempted
                      </Button>
                    </div>
                    <div className="col-sm-6 d-flex flex-column flex-1">
                      <Button
                        onClick={() => insertResult("Complete")}
                        m="0 0 1rem">
                        Complete
                      </Button>
                      <Button
                        onClick={() => insertResult("Error")}
                        m="0 0 1rem">
                        Error
                      </Button>
                      <Button
                        onClick={() => insertResult("Mismatch")}
                        m="0 0 1rem">
                        Mismatch
                      </Button>
                    </div>
                  </div>
                </Card>
              </ModalWrap>
            )}

            {recItems.editingGC && (
              <ModalWrap>
                <Card width="50%" wrapClass="p-5">
                  <div className="d-flex align-items-center justify-content-between mb-0">
                    <h3>Add Return Receipt Number</h3>
                    <FontAwesomeIcon
                      icon={faTimes}
                      color={theme.colors.text}
                      onClick={() =>
                        setRecItems({ ...recItems, editingGC: null })
                      }
                    />
                  </div>

                  <SaveInput
                    bg={theme.colors.fg}
                    placeholder="Return receipt number"
                    type="text"
                    onChange={e =>
                      setRecItems({
                        ...recItems,
                        receipt_number: e.target.value,
                      })
                    }
                    onSubmit={saveReceiptNumber}
                    autoFocus
                  />
                </Card>
              </ModalWrap>
            )}

            {/* STATUS: FINISHING */}

            {state.data && status === "FINISHING" && (
              <div>
                <p className="mb-2">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={theme.colors.green}
                  />{" "}
                  <span className="ml-3">All Items Printed</span>
                </p>

                <Button onClick={loadTruck}>Load Truck</Button>
              </div>
            )}

            {/* STATUS: IN-TRANSIT */}

            {((state.data && status === "IN-TRANSIT") ||
              status === "LOADING") && (
              <div>
                <p className="mb-2">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={theme.colors.green}
                  />{" "}
                  <span className="ml-3">
                    Truck loaded on truck_loaded_date
                  </span>
                </p>

                <div className="d-flex flex-row align-items-center mt-5">
                  <Text
                    color={theme.colors.text}
                    weight="900"
                    size="1.5rem"
                    m="0 1rem 0 0">
                    Select drop date
                  </Text>
                  <DatePicker
                    width={"65%"}
                    startDate={state.dropDate}
                    setDate={date => {
                      setState({ ...state, dropDate: date });
                    }}
                    disabledDates={"future"}
                  />
                </div>
                {state.dropDate !== null && (
                  <Button onClick={confirmDrop} m="1rem 0 0 0">
                    Confirm Drop
                  </Button>
                )}
              </div>
            )}

            {state.data && status === "MAILED" && (
              <div className="flex-column mt-3">
                <Text color={theme.colors.text} weight="900" size="2rem">
                  Congrats, this job is complete.
                </Text>
                <p>Total Letters Mailed:</p>
                <Button onClick={() => props.history.push(`/`)} m="2rem 0 0 0">
                  Back to jobs
                </Button>
              </div>
            )}
          </div>

          {/* Sidebar */}
          <div className="col-sm-3">
            <Text color={theme.colors.text} weight="900" size="2rem">
              Campaigns in Print Job
            </Text>
            {state.data &&
              state.data.campaigns &&
              state.data.campaigns.map((cmp, i) => (
                <CampaignCard key={i} campaign={cmp} />
              ))}

            {
              /*download print file*/
              state.data && state.data.print_run_output ? (
                <div className="d-flex flex-column">
                  <Text
                    color={theme.colors.text}
                    weight="900"
                    size="2rem"
                    m="1rem 0 .5rem">
                    Print Run Output
                  </Text>

                  <a href={state.data.print_run_output}>
                    <Text color={theme.colors.blue} weight="600" size="1.5rem">
                      Download zip
                    </Text>
                  </a>
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    </PageLayout>
  );

  return page;
};

export default JobDetailComponent;
