import React from 'react';
import { useSpring, animated } from 'react-spring';

const LogoSpinner = () => {
  const animateProps = useSpring({
    from: { transform: 'rotate(0deg)' },
    to: async (next) => {
      //eslint-disable-next-line
      while (1) {
        await next({ transform: 'rotate(359deg)' });
        await next({ transform: 'rotate(0deg)' });
      }
    },
    duration: 2000,
  });

  return (
    <animated.svg style={animateProps} xmlnsXlink="http://www.w3.org/1999/xlink" width="28" height="27">
      <g fill="#FF6700" fillRule="evenodd">
        <path
          id="a"
          d="M19.344.955L14 3.612c3.096 1.757 4.542 3.239
          7.306 2.572l5.014-2.891c-.548-1.178-
          1.291-2.09-2.272-2.647-2.354-1.336-3.514-.277-4.704.31"
        ></path>
      </g>
      <g fill="#E91C1C" fillRule="evenodd">
        <path
          id="b"
          d="M14.111
          3.59l-2.365-1.323C7.593-.069 7.176-.183 6.031.123 4.494.533
          2.64 1.69 1.867 3.457l9.986 5.675c1.57.782
          3.006.763 4.212-.04l5.215-2.995c-2.66.752-4.12-.77-7.169-2.506"
        ></path>
      </g>
      <g fill="#A450FF" fillRule="evenodd">
        <path
          id="c"
          d="M2.532 12.041l4.851
          3.266c.004-3.548.577-5.558-1.329-7.59l-4.89-2.864C.423 5.916 0
          7.016 0 8.14c-.003 2.698 1.457 3.17 2.532 3.901"
        ></path>
      </g>
      <g fill="#F3C343" fillRule="evenodd">
        <path
          id="d"
          d="M20.71 15.481l2.337-1.356c4.113-2.375 4.413-2.687
          4.723-3.821.416-1.524.335-3.71-.81-5.264l-9.937
          5.683c-1.467.95-2.164 2.201-2.074
          3.638l-.016 5.986c.684-2.659 2.74-3.133 5.778-4.866"
        ></path>
      </g>
      <g fill="#3FD674" fillRule="evenodd">
        <path
          id="e"
          d="M20.524 21.319l.383-5.826c-3.119 1.71-5.163 2.23-5.964
          4.744l-.01 5.666c1.316.125 2.492-.01 3.48-.55 2.371-1.301 2.023-2.754
          2.11-4.034"
        ></path>
      </g>
      <g fill="#06A3FF" fillRule="evenodd">
        <path
          d="M7.364 18.09c.02 4.802.158 5.224 1.003 6.067 1.136 1.133 3.151 2.166
        5.073 1.947V14.528c-.192-1.765-.864-3.009-2.18-3.655L5.974 7.84c1.941 1.955
        1.394 3.983 1.39 7.518v2.733z"
        ></path>
      </g>
    </animated.svg>
  );
};

export default LogoSpinner;
