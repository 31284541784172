import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useTheme} from 'emotion-theming';
import ReactPaginate from 'react-paginate';
import ClipLoader from 'react-spinners/ClipLoader';
import APIService from 'services/backstrap/apiService';
import PageLayout from 'components/UI/Layout/PageLayout';
import Table from 'components/UI/Table';
import useTableData from 'components/UI/Table/useTableData';
import {PageTitle, Text} from 'components/UI/Typography';
import {LoadingContainer} from './styles';
import { Button } from "components/UI/Button";
import DatePicker from "components/UI/Form/DatePicker";

const PAGE_SIZE = 25;

const PreMergedCampaignsReport = props => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [beginDate, setBeginDate] = useState();
  const [endDate, setEndDate] = useState();

  const columns = [
    {
      name: 'send_name',
      dataName: 'send_name',
      text: 'Send Name',
      width: '20%',
    },
    {
      name: 'sender_name',
      dataName: 'sender_name',
      text: 'Sender Name',
      width: '10%',
    },
    {
      name: 'send_id',
      dataName: 'send_id',
      text: 'Send Id',
      width: '5%',
    },
    {
      name: 'mail_class',
      dataName: 'mail_class',
      text: 'Mail Class',
      width: '10%',
    },
    {
      name: 'letter_count',
      dataName: 'letter_count',
      text: 'Letter Count',
      width: '10%',
    },
    {
      name: 'mailed_date',
      dataName: 'mailed_date',
      text: 'Mailed Date',
      width: '10%',
      dataFormat: val => moment(val).format('MM-DD-YYYY'),
    },
    {
      name: 'piece_postage_rate_total',
      dataName: 'piece_postage_rate_total',
      text: 'Postage',
      width: '10%',
      isCurrency: true
    },
    {
      name: 'extra_services_and_fees_total',
      dataName: 'extra_services_and_fees_total',
      text: 'Extra Services',
      width: '10%',
      isCurrency: true
    },
    {
      name: 'postage_total',
      dataName: 'postage_total',
      text: 'Total',
      width: '10%',
      isCurrency: true
    }
  ];

  const {tableData} = useTableData(columns, campaigns);
  const theme = useTheme();

  useEffect(() => {
    setLoading(true);
    APIService.getPreMergedCampaigns({
      limit: PAGE_SIZE, 
      page: currentPage,
      mailed_date_begin: beginDate,
      mailed_date_end: endDate
    })
    .then(response => {
      setTotalResults(response.total_results);
      setCampaigns(response.results);
      setLoading(false);
    })
    .catch(err => {
      setLoading(false);
      console.log('[error loading campaigns]', err);
    });
  }, [currentPage, endDate, beginDate]);

  const downloadReport = () => {
    setLoading(true);
    APIService.getDownloadPreMergedCampaigns({
      mailed_date_begin: beginDate,
      mailed_date_end: endDate
    })
    .then(response => {
      window.open(response.report);
      setLoading(false);
    })
    .catch(err => {
      setLoading(false);
      console.log('[error loading campaigns]', err);
    });
  }

  if (!loading) {
    return (
      <PageLayout props={props}>
        <div className="campaign-meta mb-5">
          <PageTitle>Pre-Merged Campaigns Report</PageTitle>
          <Button onClick={() => downloadReport()}>
            Export to Excel
          </Button>
        </div>
        <div className="container">
          <div className="actionBar mt-3 mb-5 bg-light border rounded p-3">
            <div className="row">
              <div className="col-sm-4" style={{zIndex: 10000}}>
                <small className="font-weight-bold">Begin Mailed Date</small>
                <DatePicker
                  width={"65%"}
                  startDate={beginDate}
                  setDate={date => setBeginDate(date)}
                  disabledDates={"future"}
                />
              </div>
              <div className="col-sm-4" style={{zIndex: 10000}}>
                <small className="font-weight-bold">End Mailed Date</small>
                <DatePicker
                  width={"65%"}
                  startDate={endDate}
                  setDate={date => setEndDate(date)}
                  disabledDates={"future"}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          name="campaigns"
          headerColumns={columns}
          data={tableData}>
        </Table>
        {
          campaigns.length > 0 ?
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(totalResults / PAGE_SIZE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={data => {
              setCurrentPage(data.selected);
            }}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
          />
          : null
        }
      </PageLayout>
    );
  }

  return (
    <PageLayout props={props}>
      <LoadingContainer>
        <ClipLoader size={25} color={'#123abc'} />
      </LoadingContainer>
    </PageLayout>
  );
};

export default PreMergedCampaignsReport;
