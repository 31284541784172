import React from "react";
import moment from "moment";
import { useTheme } from "emotion-theming";
import { Flex } from "components/UI/Layout";

import { Card } from "components/UI/Cards";
import { faInfoCircle, faList } from "@fortawesome/free-solid-svg-icons";
import IconText from "components/UI/IconText";

const CampaignCard = ({ campaign }) => {
  const theme = useTheme();
  return (
    <Card>
      {campaign ? (
        <>
          <Flex justify="space-between" p="0 1.6rem">
            <header>
              <h3
                style={{
                  fontSize: "1.75rem",
                  marginBottom: 0,
                  fontWeight: 900,
                }}>
                {campaign.name}
              </h3>
              <h3
                style={{
                  color: theme.colors.grey,
                  fontSize: "1.45rem",
                  margin: "0",
                  fontWeight: 500,
                }}>
                {campaign.send_name}
              </h3>
            </header>
          </Flex>
          <Flex justify="space-between" p="1rem 1.6rem 0">
            <Flex>
              <IconText icon={faInfoCircle} text={campaign.send_id} />
            </Flex>
            <Flex justify="flex-end">
              <IconText
                icon={faList}
                text={`${campaign.campaign_letter_count} letters, ${campaign.campaign_impression_count} impressions`}
              />
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex justify="space-between" p="0 1.6rem">
          <header>
            <h3>No Campaigns</h3>
          </header>
        </Flex>
      )}
    </Card>
  );
};

export default CampaignCard;
