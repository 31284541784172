import React from "react";
import Checkbox from "./Checkbox";
import { RowContainer } from "./styled";

const Row = ({ children, expanded, selected, setSelectedRow, dataRow }) => {
  const className = `
  ${expanded ? "expanded tableRow" : "tableRow"} 
  ${selected ? "selected" : ""} 
  `;
  return (
    <RowContainer className={className}>
      {setSelectedRow && (
        <input
          className="select-checkbox"
          type="checkbox"
          checked={selected}
          onChange={() => {
            setSelectedRow(dataRow);
          }}
        />
      )}
      <div className="cells">{children}</div>
      {expanded && <div className="expandedCells">{expanded}</div>}
    </RowContainer>
  );
};

export default Row;
