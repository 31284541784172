import React from 'react';

const SearchLogo = ({ size = 40 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166 166" height={size} width={size} id="search-logo">
    <g id="g1">
      <path
        d="M 69,148.94598 C 48.754845,145.93014 31.457079,131.86411 22.037224,
         114.01671 14.892938,101.34379 13.650988,86.202365 14.511205,71.935863 c 0.09835,
         -7.01491 4.370812,-12.511192 6.304948,-18.919287 3.66864,-6.456872 6.960276,
         -13.31536 12.936053,-18.043177 9.199336,-8.057067 19.676259,-15.148754 31.40373,
         -18.867169 12.199113,-2.02702 25.015628,-2.200848 37.067584,0.785164 11.52636,
         4.11424 22.43487,10.417138 30.37197,19.898845 6.13329,7.42817 12.46364,
         15.296804 15.26375,24.660998 2.20087,6.264289 2.20489,13.036155 1.85578,
         19.732602 -0.49328,8.304002 -0.329,16.834455 -2.61827,24.879341 -5.25086,
         9.7632 -11.95726,
         18.90527 -19.97414,26.57777 -5.89526,6.66587 -8.48845,-5.19378 -13.16403,
         -7.87835 -5.14089,-5.34032 -9.09605,-12.04077 -15.285834,-16.22713 -6.213502,
         1.37226 -12.717519,
         4.06952 -19.379196,2.77327 C 62.464834,109.74077 48.845459,91.854725 53.601428,
         75.244773 57.580056,60.598391 73.867357,49.407366 88.972369,
         54.055531 c 15.419181,
         3.967874 27.711571,21.206326 22.268881,37.051753 -2.71865,6.259948 -9.98097,
         13.614976 -1.95404,19.319556 1.62294,1.16784 3.14142,4.98213 4.83582,
         4.33983 1.66579,
         -1.94629 8.82341,-7.48662 4.9756,-7.30473 3.15817,-4.85371 5.8601,
         -10.933583 6.64425,-16.941503 C 125.92353,83.874529 126.57206,76.998033 125,
         70.5 123.72753,
         64.607201 119.95177,59.485357 116.4426,54.429061 105.77234,42.038004 88.127776,
         36.188895 72.173674,39.914818 58.705983,41.959788 48.892699,53.178175 42.390653,
         64.369995 40.034051,70.150311 38.23527,76.23839 38.253685,82.461732 c 0.512333,
         6.505435 1.343881,13.276505 4.671934,18.964398 1.844437,
         5.49839 6.052728,10.87217 11.322603,14.48189 6.654886,4.89207 13.84398,
         10.2761 22.43969,10.53599 6.673135,2.69722 14.189471,-0.95106 20.648663,
         1.0545 -4.454953,4.1647 -11.480103,3.66334 -17.244126,4.35746 -6.473664,
         0.43624 -12.610218,-1.78378 -18.533599,-4.1526 C 52.146718,
         122.21811 42.946266,115.04605 38.172324,104.99735 34.7945,99.978847 34.195876,
         93.446963 33.208141,87.411058 c -0.706784,
         -6.669606 0.08396,-13.593425 1.930931,-19.997009 1.032243,-5.647162 4.581932,
         -10.51041 8.081372,-15.167385 5.099322,
         -6.071778 11.159574,-11.326771 18.169948,-15.047641 5.992186,
         -2.663523 12.869957,-4.519381 19.707903,-4.123096 7.367356,
         -0.733749 14.348583,1.178602 21.240765,3.207414 4.2581,2.998887 10.12582,
         5.012562 13.86608,9.532824 4.79079,4.430092 8.61074,
         9.838817 11.71806,15.441743 2.13513,4.921331 3.14305,10.684011 3.20752,
         16.400588 0.991,9.031104 -0.75555,18.140594 -4.95825,
         26.189474 -1.58095,6.0481 -8.54247,9.9374 -8.803,15.92227 2.30391,
         7.57355 11.83568,9.61415 15.92491,2.19428 9.5538,-12.82117 12.8722,
         -29.585397 11.78343,-45.323025 -1.79202,-24.913312 -19.73511,
         -47.89312 -43.84244,-54.877698 -13.354988,-4.187865 -27.836492,
         -2.813342 -41.066534,1.076189 -4.813575,2.775835 -9.649384,
         4.387735 -13.898323,7.626033 -6.896584,5.91823 -13.899801,
         12.208925 -18.542217,20.104143 -10.124993,18.283664 -11.972629,
         41.631992 -1.912094,60.350398 5.480882,10.62912 13.730008,
         19.99625 23.864292,26.34338 5.71093,3.24969 11.794042,5.94227 17.986411,
         7.80297 8.834965,2.20156 18.104097,1.3492 27.098103,2.47971 -7.960864,
         2.0125 -16.398426,1.86811 -24.576162,1.55223 z M 91.249893,
         104.10721 C 102.63435,99.857957 108.71955,86.028643 104.35729,
         74.739658 100.67006,62.986181 86.746109,56.964152 75.327798,
         60.646761 61.47401,64.36336 55.447173,82.42339 62.814389,94.301683 c 5.5393,
         9.784727 17.999065,14.406287 28.435504,9.805527 z"
        style={{ fill: '#3e61d8', fillOpacity: '1' }}
        id="logo-path"
      />
    </g>
  </svg>
);

export default SearchLogo;
