import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {useGlobalState} from 'store';
import Form from 'react-bootstrap/Form';

import {Flex} from 'components/UI/Layout';
import {Card} from 'components/UI/Cards';
import TextInput from 'components/UI/TextInput/TextInput';
import Table from 'components/UI/Table';
import useTableData from 'components/UI/Table/useTableData';

import PageLayout from 'components/UI/Layout/PageLayout';
import TabsHeader from './TabsHeader';

import JobList from './JobList';

import APIService from 'services/backstrap/apiService';

const CampaignsJobsList = props => {
  const [appState, appDispatch] = useGlobalState();
  const [state, setState] = useState({
    campaigns: [],
    filterStatus: 'ALL',
    filterPrintJob: 'ALL',
    filterType: 'ALL',
    filterShowHidden: 'FALSE',
    searchTerm: '',
    typingTimeout: 0,
    pageNumber: 0,
    totalResults: 0,
    selected: [],
    isHidden: true,
    data: [],
  });

  const [statusFilter, setStatusFilter] = useState('campaigns');

  function bulkHide() {
    const ids = [];

    for (let i = 0; i < state.selected.length; i++) {
      ids.push(state.selected[i].id);
    }
    showHideCampaign(ids);
  }

  const search = async () => {
    const options = {
      limit: 20,
      page: state.pageNumber,
      show_hidden: state.filterShowHidden,
    };

    if (state.searchTerm !== '') {
      options.search_term = state.searchTerm;
    }
    if (state.filterPrintJob !== 'ALL') {
      options.mail_status = state.filterPrintJob;
    }
    if (state.filterType !== 'ALL') {
      options.mail_class = state.filterType;
    }
    if (state.filterStatus !== 'ALL') {
      options.campaign_status = state.filterStatus;
    }

    try {
      const response = await APIService.getCampaignSearch(options);
      setState({
        ...state,
        data: response.results,
        totalResults: response.total_results,
      });
    } catch (error) {
      console.log(error);
    }
  }

  function searchCampaign(e) {
    setState({...state, searchTerm: e.target.value});
  }

  useEffect(() => {
    search();
  }, [
    state.filterPrintJob,
    state.filterType,
    state.filterStatus,
    state.filterShowHidden,
  ]);

  useEffect(() => {
    if (state.typingTimeout) clearTimeout(state.typingTimeout);
    state.typingTimeout = setTimeout(() => {
      search();
    }, 500);
  }, [state.searchTerm]);

  function selectCampaign(campaign) {
    setState({...state, isHidden: false});

    if (!campaign.isSelected) {
      setState({
        ...state,
        selected: [...state.selected, campaign],
        isHidden: false,
      });
      campaign.isSelected = true;
    } else {
      campaign.isSelected = !campaign.isSelected;
      let selectedCampaigns = state.selected;
      for (let i = 0; i < selectedCampaigns.length; i++) {
        if (selectedCampaigns[i].id === campaign.id) {
          selectedCampaigns.splice(i, 1);
          setState({...state, selected: selectedCampaigns});
        }
      }
      if (state.selected.length <= 0) {
        setState({...state, isHidden: true});
      }
    }
  }

  function changePage(event) {
    const status = Number(event.target.text) - 1;
    setState({...state, pageNumber: status});
  }

  function filterPrintJob(event) {
    const status = event.target.value;
    setState({...state, filterPrintJob: status});
  }

  function filterMailClass(event) {
    const status = event.target.value;
    setState({...state, filterType: status});
  }

  function filterShowHidden(event) {
    const status = event.target.value;
    setState({...state, filterShowHidden: status});
  }

  function filterCampaignStatus(event) {
    const status = event.target.value;
    setState({...state, filterStatus: status});
  }

  function showHideCampaign(index) {
    let ids = [];

    if (Array.isArray(index)) {
      ids = index;
    } else {
      ids.push(index);
    }

    APIService.toggleHiddenCampaign(ids)
      .then(res => {
        console.log(res, 'toggle hdiden campaign');
        search();
      })
      .catch(error => {
        console.log(error);
      });
  }

  function createPrintJob() {
    const selected = state.selected.every(
      e => e.mail_type === state.selected[0].mail_type,
    );
    if (selected) {
      props.history.push({
        pathname: '/create-job',
        campaigns: state.selected,
      });
    } else {
      setState({...state, selected: []});
      appDispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          type: 'danger',
          message: 'Cannot combine mail class types when creating print job',
          active: true,
        },
      });
    }
  }

  const columns = [
    {
      name: "mail-type",
      dataName: "mail_type",
      text: "TYPE",
      width: "5%",
    },
    {
      name: "send-id",
      dataName: "send_id",
      text: "SEND ID",
      width: "10%",
    },
    {
      name: 'send-name',
      dataName: 'send_name',
      text: 'SEND NAME',
      width: '20%',
      link: row => {
        return {
          text: '/campaign-details/:campaignId',
          params: {
            ':campaignId': row.id,
          },
        };
      },
    },
    {
      name: 'sender',
      dataName: 'sender_email',
      text: 'SENDER',
      width: '12%',
    },
    {
      name: 'job-id',
      dataName: 'print_job_ids',
      text: 'JOB ID',
      width: '10%',
      align: 'center',
      link: row => {
        return {
          text: '/job-details/:jobId',
          params: {
            ':jobId': row.print_job_ids,
          },
        };
      },
    },
    {
      name: 'stage',
      dataName: 'campaign_status',
      text: 'STAGE',
      width: '10%',
    },
    {
      name: 'letters',
      dataName: 'letter_count',
      text: 'LETTERS',
      width: '5%',
      align: 'center',
    },
    {
      name: 'impressions',
      dataName: 'impression_count',
      text: 'IMPRESSIONS',
      width: '5%',
      align: 'center',
    },
    {
      name: 'send-date',
      dataName: 'mail_date',
      text: 'MAIL BY DATE ',
      width: '10%',
      dataFormat: val => moment.utc(val).format('MM-DD-YYYY'),
    },
    {
      name: "mail-status",
      dataName: "mail_status",
      text: "STATUS",
      width: "10%",
    },
  ];

  const rowOptions = [
    // {
    //   label: "Select",
    //   name: "select",
    //   icon: "check-square",
    //   action: row => selectCampaign(row.original),
    // },
    {
      label: 'Details',
      name: 'details',
      icon: 'info',
      link: row => (
        <Link to={`/campaign-details/${row.id}`}>
          <i className="fa fa-list"></i> Details
        </Link>
      ),
    },
    {
      label: 'Hide',
      name: 'hide',
      icon: 'eye-slash',
      action: row => showHideCampaign(row.id),
    },
  ];

  const selectOptions = [
    {
      items: state.selected,
      action: row => {
        selectCampaign(row.original);
      },
    },
  ];

  const {tableData} = useTableData(columns, state.data);

  const CampaignTable = () => (
    <div className="container">
      <div className="actionBar mt-3 mb-5 bg-light border rounded p-3">
        <div className="row">
          <div className="col-sm-4">
            <small className="font-weight-bold">Search</small>
            <TextInput
              className="campaign-search"
              placeholder="Filter Campaigns"
              type="text"
              onChange={e => searchCampaign(e)}
              value={state.searchTerm}
              autoFocus
            />
          </div>
          <div className="col-sm-2">
            <small className="font-weight-bold">Status</small>
            <Form.Control
              as="select"
              defaultValue={state.filterPrintJob}
              onChange={filterPrintJob}>
              <option value="ALL">ALL</option>
              <option value="ACCEPTED">ACCEPTED</option>
              <option value="PRESORT">PRESORT</option>
              <option value="PREPRESS">PREPRESS</option>
              <option value="PRINTING">PRINTING</option>
              <option value="FINISHING">FINISHING</option>
              <option value="LOADING">LOADING</option>
              <option value="IN-TRANSIT">IN-TRANSIT</option>
              <option value="MAILED">MAILED</option>
            </Form.Control>
          </div>
          <div className="col-sm-2">
            <small className="font-weight-bold">Stage</small>
            <Form.Control
              as="select"
              defaultValue={state.filterStatus}
              onChange={filterCampaignStatus}>
              <option value="ALL">ALL</option>
              <option value="IMPORTING">IMPORTING</option>
              <option value="ACTIVE"> ACTIVE</option>
              <option value="MAILED">MAILED</option>
              <option value="CANCELED">CANCELED</option>
              <option value="IMPORT-ERROR">IMPORT-ERROR</option>
            </Form.Control>
          </div>
          <div className="col-sm-2">
            <small className="font-weight-bold">Type</small>
            <Form.Control
              as="select"
              defaultValue={state.filterType}
              onChange={filterMailClass}>
              <option value="ALL">ALL</option>
              <option value="GC">GC</option>
              <option value="FC">FC</option>
              <option value="ERR">ERR</option>
            </Form.Control>
          </div>
          <div className="col-sm-2">
            <small className="font-weight-bold">Show Hidden</small>
            <Form.Control
              as="select"
              defaultValue={state.filterShowHidden}
              onChange={filterShowHidden}>
              <option value="FALSE">FALSE</option>
              <option value="TRUE">TRUE</option>
            </Form.Control>
          </div>
        </div>
      </div>

      <div className="tableContainer">
        <Table
          name="campaigns"
          headerColumns={columns}
          data={tableData}
          rowOptions={rowOptions}
          selectOptions={selectOptions}
        />
      </div>
    </div>
  );

  return (
    <PageLayout props={props}>
      {!state.isHidden && (
        <Card>
          <Flex justify="space-between" p="0 1.6rem">
            <div className="d-flex align-items-center">
              <strong>
                {state.selected && state.selected.length
                  ? `${state.selected.length} campaigns selected`
                  : `No campaigns selected`}
              </strong>
            </div>

            <div>
              <button className="btn btn-primary mr-3" onClick={createPrintJob}>
                <i className="fa fa-print fa-lg"></i> Create Print Job
              </button>

              <button className="btn btn-outline-primary" onClick={bulkHide}>
                <i className="fa fa-eye-slash fa-lg"></i> Hide
              </button>
            </div>
          </Flex>
        </Card>
      )}

      <TabsHeader
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />

      {statusFilter === 'jobs' ? <JobList /> : <CampaignTable />}
    </PageLayout>
  );
};

export default CampaignsJobsList;
