import React from "react";
import { RowDropdownContainer } from "./styled";
import Cell from "./Cell";
import RowOptions from "./RowOptions";

const RowDropdown = ({
  dataRow,
  expandedRow,
  setExpandedRow,
  rowOptions,
  setConfirmRow,
  confirmRow,
  setModalRow,
  modalRow,
}) => {
  return (
    <RowDropdownContainer key={`dropdown-${dataRow.id}`}>
      <Cell
        data={
          <RowOptions
            options={rowOptions}
            row={dataRow}
            expanded={expandedRow}
            setExpandedRow={setExpandedRow}
            setConfirm={(action, message) =>
              setConfirmRow({
                ...confirmRow,
                rowId: dataRow.id,
                action,
                message,
              })
            }
            setModal={modalContent => {
              setModalRow({
                ...modalRow,
                rowId: dataRow.id,
                modal: modalContent,
              });
              setExpandedRow({
                rowId: null,
                expandedContent: null,
              });
            }}
            setExpanded={expandedContent => {
              setExpandedRow({
                ...expandedRow,
                rowId: dataRow.id,
                expandedContent,
              });
              setModalRow({
                rowId: null,
                modal: null,
              });
            }}
          />
        }
        width="3%"
      />
    </RowDropdownContainer>
  );
};

export default RowDropdown;
