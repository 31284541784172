import styled from '@emotion/styled/macro';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  .create-client-modal {
    left: auto !important;
  }
`;

export { Backdrop, ModalContainer };
