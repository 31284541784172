import styled from '@emotion/styled/macro';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableBody = styled.div`
  ${'' /* display: flex;
  flex-direction: column; */}
  margin-top: -3.5rem;
`;

const HeaderRow = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
  position: sticky;
  margin-bottom: 30px;
  z-index: 1000;
`;

const HeaderCellContainer = styled.div`
  width: ${props => props.width};
  text-align: ${props => props.align || 'left'};
  color: ${props => props.theme.colors.text};
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 1rem 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CellContainer = styled.div`
  width: ${props => props.width};
  justify-content: ${props => props.align || 'left'};
  text-align: ${props => props.align};
  font-weight: 700;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.text};
  padding: 1.75rem 0.8rem;
  overflow: hidden;
  text-overflow: ${props => props.textWrap ? 'break-word' : 'ellipsis'};
`;

const RowContainer = styled.div`
  position: relative;
  .expandedCells ${CellContainer} {
    font-weight: 500;
  }
  &.expanded > .cells {
    box-shadow: none !important;
  }
  &:nth-of-type(even) > .cells {
    box-shadow: ${props => props.theme.boxShadow};
    background-color: ${props => props.theme.colors.fg};
  }
  &:nth-of-type(odd) > .cells {
    background-color: transparent;
  }
  .expandedCells .tableRow:nth-of-type(even) > .cells {
    box-shadow: none !important;
    background-color: ${props => props.theme.colors.bg};
  }
  .expandedCells .tableRow:nth-of-type(odd) > .cells {
    background-color: transparent;
  }
  &.expanded {
    background-color: ${props => props.theme.colors.fg};
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: ${props => props.theme.boxShadow};
  }
  .cells {
    display: flex;
    border-radius: ${props => props.theme.borderRadius};
    padding: 0.8rem 0;
    margin: 0.8rem 0;
  }
  .expandedCells {
    padding: 0 2rem;
    .cells {
      padding: 0.3rem 0;
      margin: 0.3rem 0;
    }
  }
  &.selected > .cells {
    border: 2px solid ${props => props.theme.colors.blue};
    box-shadow: ${props => props.theme.boxShadow};
    background-color: ${props => props.theme.colors.lightBlue};
  }
  & > .select-checkbox {
    position: absolute;
    left: -40px;
    top: 30px;
  }
`;

const Options = styled.ul`
  position: absolute;
  z-index: 9999;
  display: none;
  list-style: none;
  background: #111;
  padding: 0.5rem 1rem 0.75rem 0.25rem;
  line-height: 2.5rem;
  border-radius: 0.3rem;
  top: 1rem;
  left: 1.75rem;
  min-width: 160px;
  border: 1px solid #333;
  font-size: 0.8rem;
  font-weight: 400;
`;

const Option = styled.li`
  button,
  button a {
    text-decoration: none;
    color: #fff;
    &:disabled {
      cursor: not-allowed;
    }
    &:hover {
      color: #01b9ff;
      text-decoration: none;
      i {
        color: #01b9ff;
      }
    }
    i {
      padding-right: 1rem;
    }
  }
`;

const RowDropdownContainer = styled.div`
  cursor: pointer;
  position: relative;
  i {
    color: rgba(255, 255, 255, 0.02);
  }
  &:hover {
    i {
      color: rgba(255, 255, 255, 0.75);
    }
    ${Options} {
      display: block;
    }
  }
  width: 3%;
`;

const ConfirmationRowContainer = styled(RowContainer)`
  background-color: #4f2a2a !important;
  color: #c75252 !important;
  border-top: 1px solid #c75252;
  border-bottom: 1px solid #c75252;
  font-size: 14px;
  font-weight: 200;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.15rem 5rem;
  button {
    color: #fff;
    border: none;
    border-radius: 50px;
    padding: 0.3rem;
    margin: 0 0.45rem;
    width: 2.5rem;
    font-size: 1.3rem;
  }
  button.yes {
    background: #2ed283;
  }
  button.no {
    background: #fd0700;
  }
`;

const TableModalContainer = styled.div``;

const InlineLinks = styled.div`
display: flex;
justify-content: center;
`;

export {
  TableContainer,
  TableBody,
  HeaderRow,
  HeaderCellContainer,
  RowContainer,
  RowDropdownContainer,
  Options,
  Option,
  CellContainer,
  ConfirmationRowContainer,
  TableModalContainer,
  InlineLinks
};
