import React from "react";

import { Flex } from "components/UI/Layout";
import { TabButtons, TabButton } from "components/UI/Layout/TabButtons";
import { PageTitle } from "components/UI/Typography";

const TabsHeader = ({ statusFilter, setStatusFilter }) => {
  return (
    <header>
      <Flex>
        <PageTitle>
          {statusFilter === "campaigns" ? "Campaigns" : "Jobs"}
        </PageTitle>
      </Flex>
      <Flex justify="space-between">
        <TabButtons>
          <TabButton
            active={statusFilter === "jobs"}
            onClick={() => setStatusFilter("jobs")}>
            Jobs
          </TabButton>
          <TabButton
            active={statusFilter === "campaigns"}
            onClick={() => setStatusFilter("campaigns")}>
            Campaigns
          </TabButton>
        </TabButtons>
      </Flex>
    </header>
  );
};

export default TabsHeader;
